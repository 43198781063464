import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 - Não encontrado</title>
        <meta property="og:title" content="404 - Not Found" key="title" />
      </Helmet>
      <div
        className="d-flex justify-content-center align-items-center tw-h-screen"
        // style={{ height: "70vh" }}
      >
        <span className="text-muted">
          <span className="tw-font-bold tw-text-4xl">404 </span>
        </span>
      </div>
    </>
  );
};

export default NotFound;
