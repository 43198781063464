import { useEffect } from "react";
import { FormContainer, Message } from "../../components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { inputEmail } from "../../utils/dynamicForm";
import useAuthHook from "../../api/auth";
import useAuth from "../../hooks/useAuth";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  useAuth();
  const { postForgotPassword } = useAuthHook();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { isLoading, isError, error, isSuccess, mutateAsync } =
    postForgotPassword;

  useEffect(() => {
    isSuccess && reset();
  }, [isSuccess]);

  useEffect(() => {
    auth?.userInfo && navigate("/");
  }, [navigate]);

  const submitHandler = (data) => {
    mutateAsync(data);
  };
  return (
    <FormContainer>
      <Helmet>
        <title>Recuperação de senha</title>
        <meta property="og:title" content="Forgot" key="title" />
      </Helmet>

      {isSuccess && (
        <Message variant="success">
          Enviamos um email com as instruções para redefinir sua senha.
        </Message>
      )}
      {isError && <Message variant="danger">{error}</Message>}

      <div className="tw-w-full tw-bg-general tw-bg-general tw-flex tw-flex-col w-items-center tw-justify-center">
        <main className="box-login tw-bg-white tw-max-w-lg tw-mx-auto tw-p-8 tw-md:p-12 tw-my-10 tw-rounded-lg tw-shadow-2xl">
          <section className="tw-flex tw-flex-col tw-select-none">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-mb-6">
              <img
                src="/assets/images/footer_logo.svg"
                alt="logo"
                style={{ width: "180px" }}
                className="h-20 object-contain"
              />
            </div>
          </section>

          <section className="mt-6">
            <div className="tw-text-center tw-mb-4">
              <h3 className="tw-text-md tw-font-semibold tw-mb-2">
                Recuperação de senha
              </h3>
              <p className="tw-text-xs tw-text-gray-500">
                Digite seu email para redefinir sua senha.
              </p>
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
              {inputEmail({
                register,
                errors,
                label: "Email",
                name: "email",
                placeholder: "Email",
              })}

              <button
                type="submit"
                className="btn btn-primary form-control "
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  "Enviar"
                )}
              </button>
            </form>
          </section>
        </main>
      </div>
    </FormContainer>
  );
};

export default ForgotPassword;
