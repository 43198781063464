const Footer = () => {
  // const date = new Date()
  // const currentYear = date.getFullYear()

  return (
    <footer className="text-primary container-fluid">
      <div className="row">
        <div className="col text-center py-1 footer font-monospace bg-light my-auto">
          {/* V.0.0.1 */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
