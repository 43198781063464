import { FaPenAlt, FaTrash } from "react-icons/fa";
import { Search } from "..";
import { Table, Button, Space } from "antd";
import { UserSwitchOutlined } from "@ant-design/icons";
const ViewUserRoles = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
}) => {
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Cargo",
      dataIndex: "role-name",
      key: "role-name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Cargo ID",
      dataIndex: "role-type",
      key: "role-type",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Ações",
      key: "action",
      render: (_, userRole) => (
        <Space size="middle">
          <button
            className="btn btn-primary btn-sm rounded-pill"
            onClick={() => {
              console.log("EDITING", userRole);
              editHandler(userRole);
            }}
            data-bs-toggle="modal"
            data-bs-target="#userRoleModal"
          >
            <FaPenAlt />
          </button>

          <button
            className="btn btn-danger btn-sm ms-1 rounded-pill"
            onClick={() => deleteHandler(userRole._id)}
            disabled={isLoadingDelete}
          >
            {isLoadingDelete ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <span>
                <FaTrash />
              </span>
            )}
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="tw-w-full">
      <div className="tw-w-full tw-flex tw-flex-col tw-bg-gray-200 tw-py-4 tw-items-center">
        <div className="d-flex align-items-center flex-column mb-2">
          <section className="tw-w-full tw-flex tw-flex-col tw-pb-4 ">
            <h3 className="tw-text-xl tw-font-bold fw-light text-muted tw-text-center">
              Permissões de conta{" "}
              <sup className="fs-6"> [{data && data.total}] </sup>
            </h3>
          </section>
          {/* <button
            className="btn btn-outline-primary btn-sm shadow my-2"
            data-bs-toggle="modal"
            data-bs-target="#userRoleModal"
          >
            Adicionar novo cargo
          </button> */}
          <div className="col-auto">
            <Search
              placeholder="Pesquisar por nome"
              setQ={setQ}
              q={q}
              searchHandler={searchHandler}
            />
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-max-w-4xl tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6">
        <div className="tw-w-full tw-flex tw-justify-end tw-mb-2">
          <Button
            type="primary"
            shape="round"
            data-bs-toggle="modal"
            data-bs-target="#userRoleModal"
            icon={<UserSwitchOutlined />}
            size={"default"}
            className="tw-mt-3"
          >
            Definir uma conta
          </Button>
        </div>
        <div className="tw-w-full">
          <Table
            columns={columns}
            pagination={{
              position: ["none"],
            }}
            dataSource={
              data && data.data
                ? data.data.map((item) => {
                    return {
                      ...item,
                      key: item._id,
                      name: item.user.name,
                      email: item.user.email,
                      "role-name": item.role.name,
                      "role-type": item.role.type,
                    };
                  })
                : []
            }
          />
        </div>
      </div>

      {/* <table className="table table-sm table-border">
        <thead className="border-0">
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Cargo</th>
            <th>Cargo ID</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((userRole) => (
              <tr key={userRole._id}>
                <td>{userRole.user && userRole.user.name}</td>
                <td>{userRole.user && userRole.user.email}</td>
                <td>{userRole.role && userRole.role.name}</td>
                <td>{userRole.role && userRole.role.type}</td>

                <td>
                  <div className="btn-group">
                    <button
                      className="btn btn-primary btn-sm rounded-pill"
                      onClick={() => editHandler(userRole)}
                      data-bs-toggle="modal"
                      data-bs-target="#userRoleModal"
                    >
                      <FaPenAlt />
                    </button>

                    <button
                      className="btn btn-danger btn-sm ms-1 rounded-pill"
                      onClick={() => deleteHandler(userRole._id)}
                      disabled={isLoadingDelete}
                    >
                      {isLoadingDelete ? (
                        <span className="spinner-border spinner-border-sm" />
                      ) : (
                        <span>
                          <FaTrash />
                        </span>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default ViewUserRoles;
