import dynamicAPI from "./dynamicAPI";
import { useQuery, useMutation, useQueryClient } from "react-query";

const url = "/api/auth/residentes";

const queryKey = "residentes";

export default function useResidentesHook(props) {
  const { page = 1, id, q = "", limit = 25 } = props;
  const queryClient = useQueryClient();

  const getResidentes = useQuery(
    queryKey,
    async () =>
      await dynamicAPI("get", `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
    { retry: 0 }
  );

  const getResidenteById = useQuery(
    queryKey,
    async (id) => await dynamicAPI("get", `${url}/${id}`, {}),
    { retry: 0, enabled: !!id }
  );

  const updateResidente = useMutation(
    async (obj) => await dynamicAPI("put", `${url}/${obj._id}`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  const deleteResidente = useMutation(
    async (id) => await dynamicAPI("delete", `${url}/${id}`, {}),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  const postResidente = useMutation(
    async (obj) => await dynamicAPI("post", url, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  return {
    getResidentes,

    updateResidente,
    deleteResidente,
    postResidente,
    getResidenteById,
  };
}

export function useUserResidentesHook() {
  // const { id } = props;
  const queryClient = useQueryClient();

  const getUsersByResidente = useQuery(
    queryKey,
    async (id) => await dynamicAPI("get", `${url}/${id}`, {}),
    { retry: 0 }
  );

  const postUserResidente = useMutation(
    async (obj) => await dynamicAPI("post", url, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  const deleteUserResidente = useMutation(
    async (id) => await dynamicAPI("delete", `${url}/${id}`, {}),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  return {
    getUsersByResidente,
    postUserResidente,
    deleteUserResidente,
  };
}
