import React, { useState, useEffect, useRef } from "react";
import { Drawer, Space, Modal } from "antd";
import { Flipper, Flipped } from "react-flip-toolkit";
import { FlagOutlined, AlertOutlined, CheckOutlined } from "@ant-design/icons";
import { default as socket } from "./ws";
import { useParams, useNavigate } from "react-router-dom";

import moment from "moment";
// import axios from "axios";

const Sidebar = ({
  isOpen,
  setIsOpen,
  getDateHeader,
  getDateFromNow,
  onIncome,
  // getCategory,
  getCardStyle,
  // getStatusFromConditions,
  onConnection,
}) => {
  let { user_codId } = useParams();
  const [codId, setCodId] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [feed, setFeed] = useState({});
  const [feedItems, setFeedItems] = useState([]);
  // const [lastAlarm, setLastAlarm] = useState(null);
  const [tab, setTab] = useState(0);
  const [playingSound] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMSG, setModalMSG] = useState("");
  const [modaltype, setModalType] = useState(null);

  const setRead = async (id) => {
    fetch(`${process.env.REACT_APP_API_URL}/api-anglis/feed/read/${id}`, {
      method: "PUT",
    })
      .then((res) => res.json())
      .then(() => {
        getFeed(user_codId);
      });

    return true;
  };

  useEffect(() => {
    let keys = Object.keys(feed);

    let feedItems = [];

    for (let i = 0; i < keys.length; i++) {
      let unidade = feed[keys[i]];
      let unidadeFeed = unidade.feed;

      for (let j = 0; j < unidadeFeed.length; j++) {
        let el = unidadeFeed[j].status;
        const id = unidadeFeed[j]._id;
        const isRead = unidadeFeed[j].read ? 1 : 0;
        el.timestamp = moment(unidadeFeed[j].createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const props = {
          nome: unidade.nome,
          id,
          el,
          isRead,
        };

        feedItems.push(props);
      }
    }

    setFeedItems(feedItems);
  }, [feed]);

  // const renderFeed = (codId, read) => {
  //   return feedItems
  //     .filter((el) => el.isRead === read)
  //     .map((props, index) => {
  //       const { id, el, nome } = props;
  //       return (
  //         <li className="mb-6 border-b border-gray-300 pb-2" key={id}>
  //           <div
  //             onClick={() => {
  //               setRead(id);
  //             }}
  //             style={{
  //               backgroundColor:
  //                 el.stateCod === "danger"
  //                   ? "rgba(255,202,202,0.50)"
  //                   : "rgba(228,228,228,0.50)",
  //             }}
  //             className="w-full flex flex-col select-none cursor-pointer py-1"
  //           >
  //             <div className="flex flex-wrap justify-between items-center">
  //               {el.stateCod === "danger" && (
  //                 <div className="py-2 px-3">
  //                   <AlertOutlined
  //                     style={{
  //                       fontSize: "14px",
  //                       color: el.bg,
  //                     }}
  //                   />
  //                 </div>
  //               )}

  //               <div
  //                 style={{
  //                   fontSize: "14px",
  //                   color: el.bg,
  //                 }}
  //                 className="lato-black text-xl flex-1 text-left px-2"
  //               >
  //                 {el.stateTitle}
  //               </div>

  //               <div className="lato-regular text-md px-3 relative flex-1">
  //                 <div className="w-full flex flex-wrap justify-end">
  //                   <div className="pr-4 text-gray-500">
  //                     {getDateFromNow(el.timestamp)}{" "}
  //                   </div>
  //                   <div className="w-6 h-6 rounded bg-blue-300 flex items-center justify-center opacity-0 hover:opacity-100">
  //                     <CheckOutlined style={{ color: "#ffffff" }} />
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             <div
  //               style={{
  //                 fontSize: "13px",
  //               }}
  //               className="w-full flex flex-col px-2 border-gray-300"
  //             >
  //               {Object.keys(feed).length > 1 ? `${nome} > ` : ""} {el.comodo}
  //             </div>
  //           </div>

  //           <div className="w-full flex mt-2 flex-col">
  //             <div
  //               style={{
  //                 fontSize: "16px",
  //               }}
  //               className="w-full lato-black text-xl flex-1 text-left px-2"
  //             >
  //               {el.texto1}
  //             </div>
  //             <div
  //               style={{
  //                 fontSize: "16px",
  //               }}
  //               className="w-full lato-regular text-xl flex-1 text-left px-2"
  //             >
  //               {el.texto2}
  //             </div>
  //             <div
  //               style={{
  //                 fontSize: "12px",
  //               }}
  //               className="w-full lato-regular text-xl flex-1 text-center px-2 text-gray-300"
  //             >
  //               {moment(el.timestamp).format("DD/MM/YYYY HH:mm:ss")}
  //             </div>
  //           </div>
  //         </li>
  //       );
  //     });
  //   // .reverse();
  // };

  const renderFeedFlipped = (codId, read) => {
    return feedItems
      .filter((el) => el.isRead === read)
      .map((props) => {
        const { id, el, nome } = props;
        return (
          <Flipped key={id} flipId={id}>
            <li className="mb-6 border-b border-gray-300 pb-2">
              <div
                onClick={() => {
                  setRead(id);
                }}
                style={{
                  backgroundColor:
                    el.stateCod === "danger"
                      ? "rgba(255,202,202,0.50)"
                      : "rgba(228,228,228,0.50)",
                }}
                className="w-full flex flex-col select-none cursor-pointer py-1"
              >
                <div className="flex flex-wrap justify-between items-center">
                  {el.stateCod === "danger" && (
                    <div className="py-2 px-3">
                      <AlertOutlined
                        style={{
                          fontSize: "14px",
                          color: el.bg,
                        }}
                      />
                    </div>
                  )}

                  <div
                    style={{
                      fontSize: "14px",
                      color: el.bg,
                    }}
                    className="lato-black text-xl flex-1 text-left px-2"
                  >
                    {el.stateTitle}
                  </div>

                  <div className="lato-regular text-md px-3 relative flex-1">
                    <div className="w-full flex flex-wrap justify-end">
                      <div className="pr-4 text-gray-500">
                        {getDateFromNow(el.timestamp)}{" "}
                      </div>
                      <div className="w-6 h-6 rounded bg-blue-300 flex items-center justify-center opacity-0 hover:opacity-100">
                        <CheckOutlined style={{ color: "#ffffff" }} />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "13px",
                  }}
                  className="w-full flex flex-col px-2 border-gray-300"
                >
                  {Object.keys(feed).length > 1 ? `${nome} > ` : ""} {el.comodo}
                </div>
              </div>

              <div className="w-full flex mt-2 flex-col">
                <div
                  style={{
                    fontSize: "16px",
                  }}
                  className="w-full lato-black text-xl flex-1 text-left px-2"
                >
                  {el.texto1}
                </div>
                <div
                  style={{
                    fontSize: "16px",
                  }}
                  className="w-full lato-regular text-xl flex-1 text-left px-2"
                >
                  {el.texto2}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                  }}
                  className="w-full lato-regular text-xl flex-1 text-center px-2 text-gray-300"
                >
                  {moment(el.timestamp).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              </div>
            </li>
          </Flipped>
        );
      });
    // .reverse();
  };

  const showModal = (type) => {
    setIsModalOpen(true);
    setModalType(type);
  };

  const handleOk = () => {
    if (modaltype === "error") {
      navigate(`/`);
      localStorage.removeItem("chatConnected");
    }
    setModalMSG("");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    // if (modaltype === "error") {
    //   navigate(`/`);
    //   localStorage.removeItem("chatConnected");
    // }
    // setModalMSG("");
    // setIsModalOpen(false);
    socket.emit("dashboard-updated", {
      codId,
      timestamp: new Date().getTime(),
    });
    setIsModalOpen(false);
  };

  const getFeed = async (codId) => {
    // axios
    //   .get(`${process.env.REACT_APP_API_URL}/api-anglis/feed/getAll/${codId}`)
    //   .then((response) => {
    //     setFeed(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    fetch(`${process.env.REACT_APP_API_URL}/api-anglis/feed/getAll/${codId}`)
      .then((response) => response.json())
      .then((response) => {
        setFeed(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCards = (msg, notifications, user_codId, feedItems) => {
    if (!msg) return;

    let nots = [...notifications];

    console.log("updateCards__NOTS", nots);
    console.log("updateCards__MSG", msg);
    console.log("updateCards__MSG_isParent", msg.isParent);
    // alert("here");
    // console.log("updateCards__MSG", JSON.stringify(msg));

    // if (msg.unidades.length > 0) {
    msg.unidades.forEach((unidade) => {
      let unidadeId = unidade.codId;

      console.log("updateCards__MSGloop_unidade", unidadeId, user_codId);

      let notsModel = Object.keys(unidade.payload).forEach((key) => {
        let comodoModel = unidade.payload[key];
        console.log("updateCards__comodoModel", comodoModel);

        if (
          // (comodoModel.queda && comodoModel.queda > 0) ||
          comodoModel.status.stateCod === "danger" ||
          (comodoModel.status &&
            comodoModel.status.alertLevel &&
            parseInt(comodoModel.status.alertLevel) > 1)
        ) {
          const alarme_id = comodoModel.alarme_id;

          let statusId = parseInt(comodoModel?.status?.alertLevel) || 2;

          let model = {
            id: `${unidadeId}-${key}`,
            key: key,
            name: `${comodoModel.residente} - ${comodoModel.comodo}`,
            description: `${comodoModel.status.texto1} - ${comodoModel.status.stateTitle} - ${comodoModel.status.texto2}`,
            css: "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
            height: 176,
            width: 263,
            room: comodoModel.comodo,
            timestamp: new Date().getTime(),
            statusId: statusId,
            alarme_id: alarme_id,
            read: false,
            selected: false,
            ...getCardStyle(statusId, key),
          };

          if (msg.isParent) {
            console.log("updateCards__PARENT_LOGADO", model);
            nots.push(model);
          } else {
            if (String(user_codId) === String(unidadeId)) {
              console.log("updateCards__UNIDADE_LOGADA", model);
              nots.push(model);
            }
          }
        }
      });
      console.log("nots_", notsModel);
      // nots = nots.concat(notsModel);
    });

    nots.sort((a, b) => b.timestamp - a.timestamp);
    console.log("updateCards__NOTS_FINAL", feedItems);

    //
    const newNotifications = feedItems
      .filter((f) => f.isRead === 0)
      .map((f) => {
        return { id: f.id, isRead: 0, nome: f.nome, stateCod: f.el.stateCod };
      });

    setNotifications(newNotifications);
    onIncome(msg, newNotifications);
    getFeed(user_codId);
    // }

    return;
  };

  let askTimeout = useRef(null);

  useEffect(() => {
    if (askTimeout && !askTimeout.current) {
      askTimeout.current = setInterval(() => {
        socket.emit("ask-dashboard-update", user_codId);
      }, 5000);
    }

    getFeed(user_codId);

    return () => {
      console.log("socket off");
      if (socket) {
        socket.off();
        //stop-alerts-pooling
        socket.emit("stop-alerts-pooling", user_codId);
        // disconnect from the server
        // socket.disconnect();
        clearTimeout(askTimeout.current);
        askTimeout.current = null;
      }
    };
  }, [user_codId]);

  useEffect(() => {
    if (!localStorage.getItem("chatConnected")) {
      // navigate(`/`);
    }

    if (!user_codId) {
      // navigate(`/`);
      localStorage.removeItem("chatConnected");
    }

    window.addEventListener("beforeunload", () =>
      localStorage.removeItem("chatConnected")
    );

    if (!codId) {
      setCodId(user_codId);
      socket.emit("start-alerts-pooling", user_codId);
    }

    // connection status
    socket.on("connect", () => {
      console.log("socket connected");
      onConnection(true);
    });
    // disconnection status
    socket.on("disconnect", () => {
      console.log("socket disconnected");
      onConnection(false);
    });

    socket.on("UPDATE_DASHBOARD", ({ id, codId, msg }) => {
      console.log("UPDATE_DASHBOARD", id, msg);

      updateCards(msg, notifications, user_codId, feedItems);
      socket.emit("dashboard-updated", {
        codId,
        timestamp: new Date().getTime(),
      });
    });

    socket.on("COM_MSG", ({ id, codId, msg }) => {
      console.log("COM_MSG", id, codId, msg);
      if (msg && msg.status === "error") {
        // alert("error");
        // alert(msg.message);
        showModal("error");
        setModalMSG(msg.message);
      }
    });

    let objDiv = document.getElementById("msg");
    if (objDiv !== null) objDiv.scrollTop = objDiv.scrollHeight;

    return () => {
      if (socket) {
        console.log("socket off");
        socket.off();
      }
    };
  }, [user_codId, navigate, notifications, codId, playingSound, feedItems]);

  // const submitMsg = (e) => {
  //   e.preventDefault();

  //   if (msg === "") {
  //     toast("Enter a message.", {
  //       duration: 4000,
  //       // Styling
  //       style: {},
  //       className: "",
  //       // Custom Icon
  //       icon: "⚠️",
  //       // Aria
  //       role: "status",
  //       ariaLive: "polite",
  //     });
  //   } else if (toUser === codId) {
  //     toast("Select a different user.", {
  //       duration: 4000,
  //       // Styling
  //       style: {},
  //       className: "",
  //       // Custom Icon
  //       icon: "⚠️",
  //       // Aria
  //       role: "status",
  //       ariaLive: "polite",
  //     });
  //   } else if (toUser !== "") {
  //     let selectElem = document.getElementById("usersOn");
  //     selectElem.selectedIndex = 0;
  //     socket.emit("chat message private", { toUser, codId, msg });
  //     setChat([...chat, { codId, msg }]);
  //     setChat([...chat, `🔒 Private Message for ${toUser}: ${msg}`]);
  //     setMsg("");
  //     setToUser("");
  //   } else {
  //     socket.emit("chat message", { codId, msg });
  //     setChat([...chat, { codId, msg }]);
  //     setMsg("");
  //   }
  // };
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Modal
        title="Alerta"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="w-full flex flex-col">
          {modaltype === "error" && (
            <div className="w-full flex flex-col items-center justify-center">
              <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
                <AlertOutlined style={{ color: "#ff0000" }} />
              </div>
              <h1 className="text-red-500 text-lg font-bold">Erro</h1>
            </div>
          )}
          <p className="text-gray-500 text-sm">{modalMSG}</p>
        </div>
      </Modal>
      <Drawer
        // title="Create a new account"
        width={544}
        onClose={onClose}
        open={isOpen}
        rootStyle={{ boxShadow: "0px 0px 0px 0px #000000" }}
        style={{ boxShadow: "0px 0px 0px 0px #000000" }}
        styles={{
          body: {
            paddingBottom: 80,
            // backgroundColor: "#f0f2f5",
            backgroundColor: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(5px)",
            overflow: "hidden",
          },
          content: {
            padding: "0px",
            overflow: "hidden",
            clipPath: "polygon(15% 0px, 100% 0px, 100% 100%, 0px 100%, 0% 10%)",
            boxShadow:
              "-6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05)",
          },
          header: {
            backgroundColor: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(5px)",
            antDrawerHeaderTitle: "display: none",
            borderBottom: "none",
          },
          mask: {
            backgroundColor: "rgba(255,255,255,0.75)",
          },
          wrapper: {
            boxShadow: "0px 0px 0px 0px #000000",
            filter: "drop-shadow(-5px 13px 60px rgba(130, 130, 130, 0.5))",
            backdropFilter: "blur(5px)",
          },
        }}
        extra={
          <Space>
            <div
              style={{
                width: 435,
                height: 60,
                transform: "translate(0px, -16px)",
              }}
              className="relative"
            >
              <div
                style={{
                  width: 465,
                  transform: "translate(30px, 0px)",
                }}
                className="absolute top-0 right-0 w-full z-20 overflow-hidden"
              >
                <svg
                  version="1.2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 464 86"
                  width="464"
                  height="86"
                >
                  <defs>
                    <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
                      <path d="m76.05-213.49l208.98 93.05-103.05 231.44-208.98-93.04z" />
                    </clipPath>
                  </defs>
                  <style></style>
                  <g id="top-buttom">
                    <path
                      id="Caminho 1026"
                      style={{
                        fill: "#1ecfb8",
                      }}
                      className="s0"
                      d="m463-14l-35.8 96.4-301.6-25.7-40.7-70.7z"
                    />
                    <g id="graf">
                      <g id="Grupo 996" clipPath="url(#cp1)">
                        <path
                          id="Caminho 1027"
                          className="s1"
                          style={{
                            fill: "#00000000",
                            stroke: "#6967ff",
                            strokeMiterlimit: 100,
                            strokeWidth: 2,
                          }}
                          d="m183.7 17.6l11.2 63-142.5-27.9-48.7-102.3 99.5 13.4z"
                        />
                        <path
                          id="Caminho 1028"
                          style={{
                            fill: "#00000000",
                            stroke: "#6967ff",
                            strokeMiterlimit: 100,
                            strokeWidth: 2,
                          }}
                          className="s1"
                          d="m183.7 17.6l13.8-101.3 24.3-64.3 7.9 114.7-34.8 113.9z"
                        />
                        <path
                          id="Caminho 1029"
                          className="s1"
                          style={{
                            fill: "#00000000",
                            stroke: "#6967ff",
                            strokeMiterlimit: 100,
                            strokeWidth: 2,
                          }}
                          d="m103.2-36.2l-99.5-13.4 68.2-107.5 149.9 9.1-24.3 64.3z"
                        />
                      </g>
                    </g>
                    <g id="x">
                      <path
                        id="Linha 3"
                        className="cursor-pointer s2"
                        onClick={() => {
                          onClose();
                        }}
                        style={{
                          fill: "none",
                          stroke: "#000000",
                          strokeMiterlimit: 100,
                          strokeWidth: 2,
                        }}
                        d="m397 43l15 15"
                      />
                      <path
                        id="Linha 4"
                        style={{
                          fill: "none",
                          stroke: "#000000",
                          strokeMiterlimit: 100,
                          strokeWidth: 2,
                        }}
                        className="s2"
                        d="m412 43l-15 15"
                      />
                      <circle
                        className="cursor-pointer"
                        onClick={() => {
                          onClose();
                        }}
                        cx="405"
                        cy="50"
                        r="20"
                        fill="transparent"
                      ></circle>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </Space>
        }
      >
        <div className="flex w-full h-full flex-col pl-6 pr-4">
          <div className="flex w-full mb-4 items-center justify-start items-center">
            <ul className="flex flex-wrap">
              <li
                onClick={() => {
                  setTab(0);
                }}
                className={`cursor-pointer pr-4 ${
                  tab === 0
                    ? "text-blue-400 underline underline-offset-2"
                    : "text-gray-700"
                }`}
              >
                Não lidas
              </li>
              <li
                onClick={() => {
                  setTab(1);
                }}
                className={`cursor-pointer pr-4 ${
                  tab === 1
                    ? "text-blue-400 underline underline-offset-2"
                    : "text-gray-700"
                }`}
              >
                Lidas
              </li>
            </ul>
          </div>
          <div className="flex w-full mb-2 items-center justify-between">
            <div className="lato-bold text-3xl">Hoje</div>
            <div
              style={{ backgroundColor: "#154eed" }}
              className="py-2 px-3 rounded clip-bottom-right"
            >
              <FlagOutlined
                style={{
                  fontSize: "14px",
                  color: "white",
                }}
              />
            </div>
          </div>
          <div className="flex w-full mb-6 items-center justify-between ">
            {getDateHeader()}
          </div>
          <div className="flex flex-1 flex-col w-full h-full overflow-auto custom-scrollbar">
            <Flipper flipKey={feedItems.join("")} stagger>
              {/* <button onClick={shuffleList}> shuffle</button> */}
              <ul className="list">{renderFeedFlipped(user_codId, tab)}</ul>
            </Flipper>

            {/* 
            <ul className="flex w-full flex-col ">

         
            
              <FlipMove
                staggerDurationBy={0}
                staggerDelayBy={50}
                duration={300}
                delay={0}
                easing={"ease-in-out"}
                enterAnimation={"accordionVertical"}
                leaveAnimation={"accordionVertical"}
                typeName="div"
                // enterAnimation={{
                //   from: {
                //     transform: "translateY(100px)",
                //   },
                //   to: {
                //     transform: "translateY(0px)",
                //   },
                // }}
                // leaveAnimation={{
                //   from: {
                //     transform: "translateY(0px)",
                //     opacity: 1,
                //   },
                //   to: {
                //     transform: "translateY(100px)",
                //     opacity: 0,
                //   },
                // }}
              >
                {renderFeed(user_codId, tab)}

              CHAT 
              <div className="w-full flex flex-col flex-1">
                <div
                  onClick={(e) => {
                    console.log("click");
                    setMsg("test");
                    submitMsg(e);
                  }}
                  className="w-full"
                >
                  test {notifications.length}
                </div>
                <ul className="w-full">
                  {chat.map((el, index) => (
                    <li key={index} className="w-screen break-words pr-6">
                      {el.codId != null ? (
                        `${el.codId}: ${el.msg}`
                      ) : (
                        <p className="text-base font-semibold text-purple-900 rounded py-1">
                          {el}
                        </p>
                      )}
                    </li>
                  ))}
                </ul>
              </div> 
           </FlipMove> 
            </ul>
            */}
          </div>
        </div>
        {/* <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter user name",
                  },
                ]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="url"
                label="Url"
                rules={[
                  {
                    required: true,
                    message: "Please enter url",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  addonBefore="http://"
                  addonAfter=".com"
                  placeholder="Please enter url"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="owner"
                label="Owner"
                rules={[
                  {
                    required: true,
                    message: "Please select an owner",
                  },
                ]}
              >
                <Select placeholder="Please select an owner">
                  <Option value="xiao">Xiaoxiao Fu</Option>
                  <Option value="mao">Maomao Zhou</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please choose the type",
                  },
                ]}
              >
                <Select placeholder="Please choose the type">
                  <Option value="private">Private</Option>
                  <Option value="public">Public</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row> 
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="approver"
                label="Approver"
                rules={[
                  {
                    required: true,
                    message: "Please choose the approver",
                  },
                ]}
              >
                <Select placeholder="Please choose the approver">
                  <Option value="jack">Jack Ma</Option>
                  <Option value="tom">Tom Liu</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dateTime"
                label="DateTime"
                rules={[
                  {
                    required: true,
                    message: "Please choose the dateTime",
                  },
                ]}
              >
                <DatePicker.RangePicker
                  style={{
                    width: "100%",
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "please enter url description",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="please enter url description"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>*/}
      </Drawer>
    </>
  );
};
export default Sidebar;
