import { useEffect } from "react";
import { FormContainer, Message } from "../../components";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { inputPassword } from "../../utils/dynamicForm";
import useAuthHook from "../../api/auth";
import useAuth from "../../hooks/useAuth";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  const { postResetPassword } = useAuthHook();
  const param = useParams();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const { resetToken } = param;

  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: {
      admin: false,
      user: false,
    },
  });

  const { isLoading, isError, error, isSuccess, mutateAsync } =
    postResetPassword;

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      navigate("/auth/login");
    }
  }, [isSuccess]);

  useEffect(() => {
    auth?.userInfo && navigate("/");
  }, [navigate]);

  const submitHandler = (data) => {
    const password = data.password;
    mutateAsync({ password, resetToken });
  };

  return (
    <FormContainer>
      <Helmet>
        <title>Redifinição de senha</title>
        <meta property="og:title" content="Reset" key="title" />
      </Helmet>

      {isSuccess && (
        <Message variant="success">Senha redifinida com sucesso</Message>
      )}

      {isError && <Message variant="danger">{error}</Message>}
      <div className="tw-w-full tw-bg-general tw-bg-general tw-flex tw-flex-col w-items-center tw-justify-center">
        <main className="box-login tw-bg-white tw-max-w-lg tw-mx-auto tw-p-8 tw-md:p-12 tw-my-10 tw-rounded-lg tw-shadow-2xl">
          <section className="tw-flex tw-flex-col tw-select-none">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-mb-6">
              <img
                src="/assets/images/footer_logo.svg"
                alt="logo"
                style={{ width: "180px" }}
                className="h-20 object-contain"
              />
            </div>
          </section>

          <section className="mt-6">
            <div className="tw-text-center tw-mb-4">
              <h3 className="tw-text-md tw-font-semibold tw-mb-2">
                Crie sua nova senha
              </h3>
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
              {inputPassword({
                register,
                errors,
                label: "Senha",
                name: "password",
                minLength: true,
                isRequired: true,
                placeholder: "Senha",
              })}

              {inputPassword({
                register,
                errors,
                watch,
                name: "confirmPassword",
                label: "Confirmar senha",
                validate: true,
                minLength: true,
                placeholder: "Confirmar senha",
              })}

              <button
                type="submit"
                className="btn btn-primary form-control"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  "Redifinir"
                )}
              </button>
            </form>
          </section>
        </main>
      </div>
    </FormContainer>
  );
};

export default ResetPassword;
