import React from "react";
import { Button, Dropdown, Menu, Checkbox } from "antd";

import {
  UnorderedListOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";

export default function Header({
  // shuffle,
  // search,
  // setColumns,
  setFilters,
  filters,
  // setMargin,
  setSort,
  // setHeight,
  // columns,
  sortBy,
  // margin,
}) {
  const onChange = (e) => {
    console.log(`checked `, e);
    if (e.id === "all") {
      setFilters(
        filters.map((f) => {
          return { ...f, checked: true };
        })
      );
      return;
    }

    setFilters(
      filters.map((f) => {
        if (f.id === e.id) {
          return { ...f, checked: !f.checked };
        } else {
          return { ...f };
        }
      })
    );
    // setSelected(e);
  };

  const getLabel = (key) => {
    switch (key) {
      case "name":
        return "Status";
      case "room":
        return "Quarto";
      case "timestamp":
        return "Data";
      default:
        return "Nome";
    }
  };

  // const [newFilters] = useState(filters);

  // const [selected, setSelected] = useState(filters.filter((e) => e.checked));

  return (
    <div className="header w-full flex flex-wrap justify-end py-8 px-4 items-center">
      {/* <Button type="primary" onClick={shuffle}>
        Shuffle
      </Button>
      <Input
        style={{ marginLeft: 15, minWidth: 130, maxWidth: 300 }}
        suffix={
          <SmileOutlined type="search" style={{ color: "rgba(0,0,0,.25)" }} />
        }
        placeholder="input search text"
        onChange={search}
      /> */}
      <div className="w-auto flex flex-col pl-4">
        <div className="text-3xl lato-black titles-color">
          Visualização geral
        </div>
        <div className="text-md lato-regular text-gray-600 subtitles-color pl-1">
          Visão em galeria
        </div>
      </div>
      <div className="flex-1 flex justify-end pr-6">
        <Dropdown
          overlay={
            <Menu>
              {filters.map((e, index) => (
                <Menu.Item key={index}>
                  <div onClick={() => onChange(e, e.id)}>
                    <Checkbox
                      checked={e.checked}
                      onChange={() => onChange(e, e.id)}
                    >
                      {e.name}
                    </Checkbox>
                  </div>
                </Menu.Item>
              ))}
              <Menu.Item>
                <div
                  onClick={() =>
                    onChange(
                      {
                        id: "all",
                        name: "all",
                        checked: true,
                      },
                      0
                    )
                  }
                >
                  <Checkbox
                    checked={
                      filters.filter((e) => e.checked).length === filters.length
                    }
                    onChange={() =>
                      onChange(
                        {
                          id: "all",
                          name: "all",
                          checked: true,
                        },
                        0
                      )
                    }
                  >
                    Todos
                  </Checkbox>
                </div>
              </Menu.Item>
            </Menu>
          }
        >
          <Button style={{ marginLeft: 15, minWidth: 130, height: 40 }}>
            {filters.filter((e) => e.checked).length === filters.length
              ? "Sem filtro"
              : `Filtrar por [${filters.filter((e) => e.checked).length}]`}
            <UnorderedListOutlined />
          </Button>
        </Dropdown>
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu onClick={setSort}>
              <Menu.Item key="name">{getLabel("name")}</Menu.Item>
              <Menu.Item key="room">{getLabel("room")}</Menu.Item>
              <Menu.Item key="timestamp">{getLabel("timestamp")}</Menu.Item>
            </Menu>
          }
        >
          <Button
            style={{
              marginLeft: 15,
              minWidth: 130,
              // paddingBottom: 10,
              // paddingTop: 10,
              height: 40,
            }}
          >
            Ordenar por {getLabel(sortBy)}
            <SortAscendingOutlined />
          </Button>
        </Dropdown>
        {/* <Dropdown
        trigger={["click"]}
        overlay={
          <Menu onClick={setMargin}>
            <Menu.Item key="0">0</Menu.Item>
            <Menu.Item key="20">20</Menu.Item>
            <Menu.Item key="40">40</Menu.Item>
            <Menu.Item key="70">70</Menu.Item>
          </Menu>
        }
      >
        <Button style={{ marginLeft: 15, minWidth: 130 }}>
          {margin} px margin <SmileOutlined type="down" />
        </Button>
      </Dropdown> */}
        {/* <span style={{ marginLeft: 15 }}>Individual height</span>
      <Switch style={{ marginLeft: 15 }} defaultChecked onChange={setHeight} /> */}
      </div>
    </div>
  );
}
