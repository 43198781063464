import {
  FaPenAlt,
  FaTrash,
  // FaCheckCircle,
  // FaTimesCircle,
} from "react-icons/fa";
import { Search } from "..";
import { Table, Space, Button, Alert } from "antd";
import { UserSwitchOutlined } from "@ant-design/icons";

const ViewPermissions = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
}) => {
  const method = (color, method, permission) => {
    return (
      permission.method === method && (
        <span className={`badge bg-${color} px-2`}>{permission.method}</span>
      )
    );
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Método",
      dataIndex: "method",
      key: "method",
      render: (_, permission) => (
        <div>
          {method("primary", "GET", permission)}
          {method("success", "POST", permission)}
          {method("warning", "PUT", permission)}
          {method("danger", "DELETE", permission)}
        </div>
      ),
    },
    {
      title: "Rota",
      dataIndex: "route",
      key: "route",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Auth",
      dataIndex: "auth",
      key: "auth",
      render: (item) =>
        item ? (
          <Alert message={"Sim"} type="success" showIcon />
        ) : (
          <Alert message={"Não"} type="error" showIcon />
        ),
    },
    {
      title: "Ações",
      key: "action",
      render: (_, user) => (
        <Space size="middle">
          <button
            className="btn btn-primary btn-sm rounded-pill"
            onClick={() => editHandler(user)}
            data-bs-toggle="modal"
            data-bs-target="#permissionModal"
          >
            <FaPenAlt />
          </button>

          <button
            className="btn btn-danger btn-sm ms-1 rounded-pill"
            onClick={() => deleteHandler(user._id)}
            disabled={isLoadingDelete}
          >
            {isLoadingDelete ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <span>
                <FaTrash />
              </span>
            )}
          </button>
        </Space>
      ),
    },
  ];
  return (
    <div className="tw-w-full">
      <div className="tw-w-full tw-flex tw-flex-col tw-bg-gray-200 tw-py-4 tw-items-center">
        <div className="d-flex align-items-center flex-column mb-2">
          <section className="tw-w-full tw-flex tw-flex-col tw-pb-4 ">
            <h3 className="tw-text-xl tw-font-bold fw-light text-muted tw-text-center">
              Permissoẽs de API
              <sup className="fs-6"> [{data && data.total}] </sup>
            </h3>
          </section>
          {/* <button
            className="btn btn-outline-primary btn-sm shadow my-2"
            data-bs-toggle="modal"
            data-bs-target="#permissionModal"
          >
            Adicionar nova permissão
          </button> */}
          <div className="col-auto">
            <Search
              placeholder="Pesquisar por nome"
              setQ={setQ}
              q={q}
              searchHandler={searchHandler}
            />
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-max-w-4xl tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6">
        <div className="tw-w-full tw-flex tw-justify-end tw-mb-2">
          <Button
            type="primary"
            shape="round"
            data-bs-toggle="modal"
            data-bs-target="#permissionModal"
            icon={<UserSwitchOutlined />}
            size={"default"}
            className="tw-mt-3"
          >
            Adicionar rota
          </Button>
        </div>
        <div className="tw-w-full">
          <Table
            columns={columns}
            pagination={{
              position: ["none"],
            }}
            dataSource={
              data && data.data
                ? data.data.map((user) => {
                    return {
                      key: user._id,
                      ...user,
                    };
                  })
                : []
            }
          />
        </div>
      </div>
      {/* <table className="table table-sm table-border">
        <thead className="border-0">
          <tr>
            <th>Nome</th>
            <th>Método</th>
            <th>Rota</th>
            <th>Auth</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((permission) => (
              <tr key={permission._id}>
                <td>{permission.name}</td>
                <td>
                  {method("primary", "GET", permission)}
                  {method("success", "POST", permission)}
                  {method("warning", "PUT", permission)}
                  {method("danger", "DELETE", permission)}
                </td>
                <td>{permission.route}</td>
                <td>
                  {permission.auth ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <FaTimesCircle className="text-danger" />
                  )}
                </td>

                <td>
                  <div className="btn-group">
                    <button
                      className="btn btn-primary btn-sm rounded-pill"
                      onClick={() => editHandler(permission)}
                      data-bs-toggle="modal"
                      data-bs-target="#permissionModal"
                    >
                      <FaPenAlt />
                    </button>

                    <button
                      className="btn btn-danger btn-sm ms-1 rounded-pill"
                      onClick={() => deleteHandler(permission._id)}
                      disabled={isLoadingDelete}
                    >
                      {isLoadingDelete ? (
                        <span className="spinner-border spinner-border-sm" />
                      ) : (
                        <span>
                          <FaTrash />
                        </span>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default ViewPermissions;
