// import { FaSearch } from "react-icons/fa";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";

const Search = ({ q, setQ, placeholder, searchHandler }) => {
  return (
    <form onSubmit={searchHandler}>
      <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center">
        {/* <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          aria-label="Search"
          onChange={(e) => setQ(e.target.value)}
          value={q}
        /> */}
        <div className="tw-w-3/4 ">
          <Input
            value={q}
            onChange={(e) => setQ(e.target.value)}
            placeholder={placeholder}
          />
        </div>
        <div className="tw-w-1/4 tw-pl-1">
          {/* <button type="submit" className="btn btn-outline-secondary">
            <FaSearch />
          </button> */}
          <Button
            htmlType="submit"
            type="primary"
            icon={<SearchOutlined />}
            iconPosition={"end"}
          >
            Pesquisar
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Search;
