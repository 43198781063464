import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Message } from "../../components";
import { useForm } from "react-hook-form";
import useAuthHook from "../../api/auth";
import useUserRolesHook from "../../api/userRoles";
import { inputLoginEmail, inputLoginPassword } from "../../utils/dynamicForm";
import useAuth from "../../hooks/useAuth";
import { Helmet } from "react-helmet";

const Login = () => {
  let [searchParams] = useSearchParams();
  const pathName = searchParams.get("next") || "/";
  const navigate = useNavigate();

  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { setAuth } = useAuth();

  const { postLogin } = useAuthHook();
  const { postUserRoleById } = useUserRolesHook({
    page: 1,
    q: "",
    limit: 10000000,
  });

  const { isLoading, isError, error, mutateAsync, isSuccess, data } = postLogin;
  const {
    mutateAsync: userRoleMutateAsync,
    data: userRole,
    error: errorUserRole,
    isError: isErrorUserRole,
  } = postUserRoleById;

  useEffect(() => {
    if (isSuccess) {
      userRoleMutateAsync(data._id);
      if (userRole) {
        localStorage.setItem("userRole", JSON.stringify(userRole));
        localStorage.setItem("userInfo", JSON.stringify(data));

        setAuth({
          userInfo: data,
          userRole: userRole,
        });
        navigate(pathName);
      }
    }
  }, [isSuccess, userRole]);

  useEffect(() => {
    auth?.userInfo && navigate("/");
  }, [navigate]);

  const submitHandler = async (data) => {
    mutateAsync(data);
  };

  return (
    <>
      {/* <FormContainer> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anglis - Bem vindo ao Sistema Anglis</title>
        <link rel="canonical" href="https://dashboard.anglis.com.br" />
        <meta property="og:title" content="Login" key="title" />
      </Helmet>
      {isError && <Message variant="danger">{error}</Message>}
      {isErrorUserRole && <Message variant="danger">{errorUserRole}</Message>}

      <div className="tw-w-full tw-bg-general tw-bg-general tw-flex tw-flex-col tw-h-screen tw-items-center tw-justify-center">
        <main className="box-login tw-bg-white tw-max-w-lg tw-mx-auto tw-p-8 tw-md:p-12 tw-my-10 tw-rounded-lg tw-shadow-2xl">
          <section className="tw-flex tw-flex-col tw-select-none">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-mb-6">
              <img
                src="/assets/images/footer_logo.svg"
                alt="logo"
                style={{ width: "180px" }}
                className="h-20 object-contain"
              />
            </div>
          </section>

          <section className="mt-6">
            <form
              onSubmit={handleSubmit(submitHandler)}
              className="tw-flex tw-flex-col"
            >
              <div className="tw-mb-6 tw-rounded tw-bg-gray-200">
                {/* <label
                  className="block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2 tw-ml-3 select-none"
                  htmlFor="user"
                  onClick={() => {
                    // set focus to password input
                    document.getElementById("user").focus();
                  }}
                >
                  Email
                </label>
                <input
                  type="text"
                  onChange={(e) => () => {
                    console.log(e.target.value);
                  }}
                  id="user"
                  className="tw-bg-gray-200 rounded w-full tw-text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 tw-transition tw-duration-500 tw-px-3 tw-pb-3"
                /> */}
                <div className=" tw-w-full tw-rounded tw-text-xs tw-flex tw-justify-between">
                  {inputLoginEmail({
                    register,
                    errors,
                    label: "Email",
                    name: "email",
                    placeholder: "Email",
                    classes:
                      "tw-pt-2 tw-select-none tw-bg-gray-100 tw-rounded tw-w-full tw-text-gray-700 tw-focus:outline-none tw-border-b-4 tw-border-gray-300 tw-focus:border-purple-600 tw-transition tw-duration-500 tw-px-3 tw-pb-3",
                  })}
                </div>
              </div>
              <div className="tw-rounded tw-bg-gray-200">
                {/* <label
                  className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2 tw-ml-3 select-none"
                  htmlFor="password"
                  onClick={() => {
                    // set focus to password input
                    document.getElementById("password").focus();
                  }}
                >
                  Senha
                </label>
                <input
                  type="password"
                  onChange={(e) => () => {
                    console.log(e.target.value);
                  }}
                  id="password"
                  className="select-none tw-bg-gray-200 rounded w-full tw-text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 tw-transition tw-duration-500 tw-px-3 tw-pb-3"
                /> */}
                <div className="tw-flex tw-justify-between">
                  {inputLoginPassword({
                    register,
                    errors,
                    label: "Senha",
                    name: "password",
                    placeholder: "Senha",
                    classes:
                      "tw-pt-2 tw-select-none tw-bg-gray-100 tw-rounded tw-w-full tw-text-gray-700 tw-focus:outline-none tw-border-b-4 tw-border-gray-300 tw-focus:border-purple-600 tw-transition tw-duration-500 tw-px-3 tw-pb-3",
                  })}
                </div>
              </div>
              <div className="tw-flex justify-end select-none ">
                <Link
                  to="/auth/forgot-password"
                  className="ps-1 w-text-sm tw-text-purple-600 hover:tw-text-purple-700 hover:underline tw-mb-6"
                >
                  Esqueceu a senha?
                </Link>
              </div>
              {/* <button
                onClick={() => {
                  // submitcodId();
                  // handleOnClick();
                  console.log("click");
                }}
                className="tw-w-full select-none tw-bg-button-form hover:tw-bg-purple-700 text-white tw-font-bold py-2 tw-rounded tw-shadow-lg tw-hover:shadow-xl tw-transition tw-duration-200"
                type="submit"
              >
                Entrar
              </button> */}
              <button
                type="submit"
                className="btn btn-primary form-control "
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  "Entrar"
                )}
              </button>
            </form>{" "}
            {/* */}
          </section>
        </main>
      </div>

      {/* </FormContainer> */}
    </>
  );
};

export default Login;
