import axios from "axios";
import { config } from "../utils/customLocalStorage";

const dynamicAPI = async (method, url, obj = {}) => {
  let api_endpoint = "http://localhost:9001";

  // if prod
  if (process.env.NODE_ENV === "production") {
    api_endpoint = "https://core.anglis.com.br";
  }

  try {
    switch (method) {
      case "get":
        return await axios
          .get(`${api_endpoint}${url}`, config())
          .then((res) => res.data);

      case "post":
        return await axios
          .post(`${api_endpoint}${url}`, obj, config())
          .then((res) => res.data);

      case "put":
        return await axios
          .put(`${api_endpoint}${url}`, obj, config())
          .then((res) => res.data);

      case "delete":
        return await axios
          .delete(`${api_endpoint}${url}`, config())
          .then((res) => res.data);
    }
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export default dynamicAPI;
