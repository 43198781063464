import { FaPenAlt, FaTrash } from "react-icons/fa";
import { Search } from "..";
import { Table, Space, Button } from "antd";
import { DatabaseOutlined } from "@ant-design/icons";

const ViewClientPermissions = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
}) => {
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Menu",
      dataIndex: "menu",
      key: "menu",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Ações",
      key: "action",
      render: (_, user) => (
        <Space size="middle">
          <button
            className="btn btn-primary btn-sm rounded-pill"
            onClick={() => editHandler(user)}
            data-bs-toggle="modal"
            data-bs-target="#clientPermissionModal"
          >
            <FaPenAlt />
          </button>

          <button
            className="btn btn-danger btn-sm ms-1 rounded-pill"
            onClick={() => deleteHandler(user._id)}
            disabled={isLoadingDelete}
          >
            {isLoadingDelete ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <span>
                <FaTrash />
              </span>
            )}
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="tw-w-full">
      <div className="tw-w-full tw-flex tw-flex-col tw-bg-gray-200 tw-py-4 tw-items-center">
        <div className="d-flex align-items-center flex-column mb-2">
          <section className="tw-w-full tw-flex tw-flex-col tw-pb-4 ">
            <h3 className="tw-text-xl tw-font-bold fw-light text-muted tw-text-center">
              Permissões administrativas{" "}
              <sup className="fs-6"> [{data && data.total}] </sup>
            </h3>
          </section>
          {/* <button
            className="btn btn-outline-primary btn-sm shadow my-2"
            data-bs-toggle="modal"
            data-bs-target="#clientPermissionModal"
          >
            Adicionar permissão
          </button> */}
          <div className="col-auto">
            <Search
              placeholder="Pesquisar por nome"
              setQ={setQ}
              q={q}
              searchHandler={searchHandler}
            />
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-max-w-4xl tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6">
        <div className="tw-w-full tw-flex tw-justify-end tw-mb-2">
          <Button
            type="primary"
            shape="round"
            data-bs-toggle="modal"
            data-bs-target="#clientPermissionModal"
            icon={<DatabaseOutlined />}
            size={"default"}
            className="tw-mt-3"
          >
            Adicionar permissão
          </Button>
        </div>
        <div className="tw-w-full">
          <Table
            columns={columns}
            pagination={{
              position: ["none"],
            }}
            dataSource={
              data && data.data
                ? data.data.map((user) => {
                    return {
                      key: user._id,
                      ...user,
                    };
                  })
                : []
            }
          />
        </div>
      </div>
      {/*
      <table className="table table-sm table-border">
        <thead className="border-0">
          <tr>
            <th>Name</th>
            <th>Menu</th>
            <th>Path</th>
            <th>Descrição</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((clientPermission) => (
              <tr key={clientPermission._id}>
                <td>{clientPermission.name}</td>
                <td>{clientPermission.menu}</td>
                <td>{clientPermission.path}</td>
                <td>{clientPermission.description}</td>
                <td>
                  <div className="btn-group">
                    <button
                      className="btn btn-primary btn-sm rounded-pill"
                      onClick={() => editHandler(clientPermission)}
                      data-bs-toggle="modal"
                      data-bs-target="#clientPermissionModal"
                    >
                      <FaPenAlt />
                    </button>

                    <button
                      className="btn btn-danger btn-sm ms-1 rounded-pill"
                      onClick={() => deleteHandler(clientPermission._id)}
                      disabled={isLoadingDelete}
                    >
                      {isLoadingDelete ? (
                        <span className="spinner-border spinner-border-sm" />
                      ) : (
                        <span>
                          <FaTrash />
                        </span>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>{" "}
     */}
    </div>
  );
};

export default ViewClientPermissions;
