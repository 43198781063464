import Navigation from "./Navigation";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <>
      <Navigation />
      <div
        style={{
          backgroundImage: `url('/assets/images/bg_general.png')`,
          backgroundAttachment: "fixed",
          overflow: "auto",
        }}
        className="tw-w-full tw-flex tw-justify-center tw-p-0 tw-h-screen tw-pt-16"
      >
        <main
          className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center "
          style={{ minHeight: "70vh" }}
        >
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
}
