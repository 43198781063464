import { Spinner, Message } from "../../components";
import { dynamicInputSelect } from "../../utils/dynamicForm";
import {
  // inputCheckBox,
  inputEmail,
  // inputPassword,
  inputText,
} from "../../utils/dynamicForm";

const FormUsers = ({
  edit,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  dataRoles,
  // watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
}) => {
  return (
    <div
      className="modal fade"
      id="userModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="userModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content modal-background">
          <div className="modal-header">
            <h3 className="modal-title " id="userModalLabel">
              {edit ? "Editar conta" : "Adicionar conta"}
            </h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className="modal-body">
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>
                {inputText({
                  register,
                  errors,
                  label: "Nome",
                  name: "name",
                  placeholder: "Nome",
                })}
                {inputEmail({
                  register,
                  errors,
                  label: "Email",
                  name: "email",
                  placeholder: "Email",
                  disabled: edit ? true : false,
                })}

                {!edit ? (
                  <>
                    {dynamicInputSelect({
                      register,
                      errors,
                      label: "Cargo",
                      name: "role",
                      placeholder: "Cargo",
                      isRequired: true,
                      data: dataRoles && dataRoles.data,
                      value: "name",
                    })}
                  </>
                ) : null}

                {/* {inputPassword({
                  register,
                  errors,
                  label: "Senha",
                  name: "password",
                  minLength: true,
                  isRequired: false,
                  placeholder: "Senha",
                })}

                {inputPassword({
                  register,
                  errors,
                  watch,
                  name: "confirmPassword",
                  label: "Confirmar senha",
                  validate: true,
                  minLength: true,
                  isRequired: false,
                  placeholder: "Confirmar senha",
                })}

                {inputCheckBox({
                  register,
                  errors,
                  watch,
                  name: "confirmed",
                  label: "Confirmado",
                  isRequired: false,
                  placeholder: "Confirmado",
                })}

                {inputCheckBox({
                  register,
                  errors,
                  watch,
                  name: "blocked",
                  label: "Bloqueado",
                  isRequired: false,
                  placeholder: "Bloqueado",
                })} */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary "
                    data-bs-dismiss="modal"
                    onClick={formCleanHandler}
                  >
                    Fechar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary "
                    disabled={isLoadingPost || isLoadingUpdate}
                  >
                    {isLoadingPost || isLoadingUpdate ? (
                      <span className="spinner-border spinner-border-sm" />
                    ) : !edit ? (
                      "Enviar Convite"
                    ) : (
                      "Salvar"
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormUsers;
