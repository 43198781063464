import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import { useForm } from "react-hook-form";
import useRolesHook from "../../../api/roles";
import usePermissionsHook from "../../../api/permissions";
import useClientPermissionsHook from "../../../api/clientPermissions";
import {
  Spinner,
  ViewRoles,
  Pagination,
  FormRoles,
  Message,
  Confirm,
} from "../../../components";

const Roles = () => {
  const [page, setPage] = useState(1);
  const [id, setId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [q, setQ] = useState("");

  const { getRoles, postRole, updateRole, deleteRole } = useRolesHook({
    page,
    q,
  });
  const { getPermissions } = usePermissionsHook({
    limit: 1000000,
  });
  const { getClientPermissions } = useClientPermissionsHook({
    limit: 1000000,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const { data, isLoading, isError, error, refetch } = getRoles;
  const { data: permissionData } = getPermissions;
  const { data: clientPermissionData } = getClientPermissions;

  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateRole;

  const {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete,
    isSuccess: isSuccessDelete,
    mutateAsync: mutateAsyncDelete,
  } = deleteRole;

  const {
    isLoading: isLoadingPost,
    isError: isErrorPost,
    error: errorPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postRole;

  const formCleanHandler = () => {
    setEdit(false);
    reset();
  };

  useEffect(() => {
    if (isSuccessPost || isSuccessUpdate) formCleanHandler();
  }, [isSuccessPost, isSuccessUpdate]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (!q) refetch();
  }, [q]);

  const searchHandler = (e) => {
    e.preventDefault();
    refetch();
    setPage(1);
  };

  const deleteHandler = (id) => {
    confirmAlert(Confirm(() => mutateAsyncDelete(id)));
  };

  const submitHandler = (data) => {
    edit
      ? mutateAsyncUpdate({
          _id: id,
          name: data.name,
          description: data.description,
          permission: data.permission,
          clientPermission: data.clientPermission,
        })
      : mutateAsyncPost(data);
  };

  const editHandler = (role) => {
    setId(role._id);
    setEdit(true);
    setValue("name", role.name);
    setValue("description", role.description);
    setValue(
      "permission",
      role.permission && role.permission.map((item) => item._id)
    );
    setValue(
      "clientPermission",
      role.clientPermission && role.clientPermission.map((item) => item._id)
    );
  };

  return (
    <>
      <Helmet>
        <title>Cargos</title>
        <meta property="og:title" content="Roles" key="title" />
      </Helmet>
      {isSuccessDelete && (
        <Message variant="success">Role removido com sucesso.</Message>
      )}
      {isErrorDelete && <Message variant="danger">{errorDelete}</Message>}
      {isSuccessUpdate && (
        <Message variant="success">Role atualizado com sucesso.</Message>
      )}
      {isErrorUpdate && <Message variant="danger">{errorUpdate}</Message>}
      {isSuccessPost && (
        <Message variant="success">Role adicionado com sucesso.</Message>
      )}
      {isErrorPost && <Message variant="danger">{errorPost}</Message>}

      <FormRoles
        edit={edit}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        isLoadingUpdate={isLoadingUpdate}
        isLoadingPost={isLoadingPost}
        register={register}
        handleSubmit={handleSubmit}
        submitHandler={submitHandler}
        watch={watch}
        error={error}
        permissionData={permissionData && permissionData.data}
        clientPermissionData={clientPermissionData && clientPermissionData.data}
      />

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <ViewRoles
          data={data}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          isLoadingDelete={isLoadingDelete}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
        />
      )}

      <div className="tw-w-full tw-px-6">
        <Pagination data={data} setPage={setPage} />
      </div>
    </>
  );
};

export default Roles;
