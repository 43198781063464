import { Howl } from "howler";
import React, { useState, useEffect } from "react";

const alarmSFX = new Howl({
  src: ["/assets/alarm.wav"],
  loop: true,
});

function HeaderTop({
  isOpen,
  setIsOpen,
  getDateHeader,
  // dataSRC,
  notificationsBell,
}) {
  const unreadMessages = notificationsBell.length;

  const [playingSound, setPlayingSound] = useState(false);

  useEffect(() => {
    if (notificationsBell.length > 0) {
      const soundAlarms = notificationsBell.filter(
        (el) => el.stateCod === "danger"
      );

      if (soundAlarms.length > 0) {
        if (!playingSound) {
          alarmSFX.play();
          setPlayingSound(true);
        }
      } else {
        setPlayingSound(false);
        alarmSFX.stop();
      }
    } else {
      setPlayingSound(false);
      alarmSFX.stop();
    }
  }, [notificationsBell, playingSound]);

  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center flex-row flex-wrap">
        <div id="headerLogo" className="flex flex-row py-2 px-2">
          <img
            className="object-contain header-logo"
            src="/assets/images/logo_anglis_m.svg"
            alt="logo"
          />
        </div>
        <div className="flex flex-row flex-wrap"></div>
        <div className="flex flex-row flex-wrap">
          <div className="flex-1 flex items-center pr-2 text-sm">
            {getDateHeader()}
          </div>
          <div
            onClick={() => {
              console.log("setting_sidebar", isOpen);
              setIsOpen(!isOpen);
            }}
            className="w-12 h-12 flex items-center justify-center mr-2 relative cursor-pointer"
          >
            {unreadMessages > 0 && (
              <div
                style={{ backgroundColor: "#fa4a33" }}
                className="top-0 right-0 absolute w-4 h-4 rounded-full flex items-center justify-center text-white text-xs mr-2 mt-1"
              >
                {unreadMessages}
              </div>
            )}
            <img
              className="w-6 object-contain cursor-pointer"
              src="/assets/images/icon_bell.svg"
              alt="user"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderTop;
