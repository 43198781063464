import moment from "moment";
import {
  FaPenAlt,
  FaTrash,
  // FaCheckCircle,
  // FaTimesCircle,
} from "react-icons/fa";
import { Search } from "..";
import { UserAddOutlined } from "@ant-design/icons";

import { Table, Space, Alert, Button } from "antd";

const ViewUsers = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete,
  setQ,
  q,
  searchHandler,
}) => {
  const columns = [
    {
      title: "Data de criação",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => moment(item).format("DD/MM/yyyy HH:mm"),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Confirmado",
      dataIndex: "confirmed",
      key: "confirmed",
      render: (item) =>
        item ? (
          <Alert message={"Sim"} type="success" showIcon />
        ) : (
          <Alert message={"Não"} type="success" showIcon />
        ),
    },
    {
      title: "Bloqueado",
      dataIndex: "blocked",
      key: "blocked",
      render: (item) =>
        item ? (
          <Alert message={"Sim"} type="success" showIcon />
        ) : (
          <Alert message={"Não"} type="error" showIcon />
        ),
    },
    // {
    //   title: 'Tags',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? 'geekblue' : 'green';
    //         if (tag === 'loser') {
    //           color = 'volcano';
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "Ações",
      key: "action",
      render: (_, user) => (
        <Space size="middle">
          <button
            className="btn btn-primary btn-sm rounded-pill"
            onClick={() => {
              console.log("EDITING", user);
              editHandler(user);
            }}
            data-bs-toggle="modal"
            data-bs-target="#userModal"
          >
            <FaPenAlt />
          </button>

          <button
            className="btn btn-danger btn-sm ms-1 rounded-pill"
            onClick={() => deleteHandler(user._id)}
            disabled={isLoadingDelete}
          >
            {isLoadingDelete ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <span>
                <FaTrash />
              </span>
            )}
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="tw-w-full">
      <div className="tw-w-full tw-flex tw-flex-col tw-bg-gray-200 tw-py-4 tw-items-center">
        <div className="d-flex align-items-center flex-column mb-2">
          <section className="tw-w-full tw-flex tw-flex-col tw-pb-4 ">
            <h3 className="tw-text-xl tw-font-bold fw-light text-muted tw-text-center">
              Contas{" "}
              <sup className="fs-6">
                {" "}
                [{data && data?.data && data?.data.length}]{" "}
              </sup>
            </h3>
          </section>

          <div className="col-auto">
            <Search
              placeholder="Pesquisar por email"
              setQ={setQ}
              q={q}
              searchHandler={searchHandler}
            />
          </div>

          {/* <button className="btn btn-outline-primary btn-sm shadow my-2">
            Adicionar usuário
          </button> */}
        </div>
      </div>
      <div className="tw-w-full tw-max-w-4xl tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6">
        <div className="tw-w-full tw-flex tw-justify-end tw-mb-2">
          <Button
            type="primary"
            shape="round"
            data-bs-toggle="modal"
            data-bs-target="#userModal"
            icon={<UserAddOutlined />}
            size={"default"}
            className="tw-mt-3"
          >
            Adicionar conta
          </Button>
        </div>
        <div className="tw-w-full">
          <Table
            columns={columns}
            pagination={{
              position: ["none"],
            }}
            dataSource={
              data && data.data
                ? data.data.map((user) => {
                    return {
                      ...user,
                      key: user._id,
                      name: user.name,
                      email: user.email,
                      confirmed: user.confirmed,
                      blocked: user.blocked,
                      createdAt: user.createdAt,
                    };
                  })
                : []
            }
          />
          {/* 
        <table className="table table-sm table-border">
          <thead className="border-0">
            <tr>
              <th>Data de cadastro</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Confirmado</th>
              <th>Bloqueado</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.data.map((user) => (
                <tr key={user._id}>
                  <td>{moment(user.createdAt).format("DD/MM/yyyy")}</td>
                  <td>{user.name}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {user.confirmed ? (
                      <FaCheckCircle className="text-success" />
                    ) : (
                      <FaTimesCircle className="text-danger" />
                    )}
                  </td>
                  <td>
                    {user.blocked ? (
                      <FaCheckCircle className="text-success" />
                    ) : (
                      <FaTimesCircle className="text-danger" />
                    )}
                  </td>

                  <td>
                    <div className="btn-group">
                      <button
                        className="btn btn-primary btn-sm rounded-pill"
                        onClick={() => editHandler(user)}
                        data-bs-toggle="modal"
                        data-bs-target="#userModal"
                      >
                        <FaPenAlt />
                      </button>

                      <button
                        className="btn btn-danger btn-sm ms-1 rounded-pill"
                        onClick={() => deleteHandler(user._id)}
                        disabled={isLoadingDelete}
                      >
                        {isLoadingDelete ? (
                          <span className="spinner-border spinner-border-sm" />
                        ) : (
                          <span>
                            <FaTrash />
                          </span>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table> */}
        </div>
      </div>
    </div>
  );
};

export default ViewUsers;
