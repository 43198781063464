import LoadingIcons from "react-loading-icons";

export const Spinner = (props) => {
  const { height = "3em", stroke = "#06bcee" } = props;
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center">
      <LoadingIcons.TailSpin
        stroke={stroke}
        height={height}
        // fill="transparent"
      />
      <p style={{ color: "#06bcee" }}>Carregando...</p>
    </div>
  );
};
