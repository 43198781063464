import { useEffect, useState } from "react";
import { FormContainer, Message } from "../../components";
import { useForm } from "react-hook-form";
import useProfilesHook from "../../api/profiles";
import useUploadHook from "../../api/upload";
import { Helmet } from "react-helmet";
import {
  inputFile,
  inputPassword,
  inputTel,
  inputText,
  // inputNumber,
  inputTextArea,
} from "../../utils/dynamicForm";
import { Spinner } from "../../components";

const Profile = () => {
  const [file, setFile] = useState(null);
  const [fileLink, setFileLink] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const { getProfile, postProfile } = useProfilesHook({
    page: 1,
    q: "",
    limit: 25,
  });
  const { postUpload } = useUploadHook();

  const { data, isLoading, isError, error } = getProfile;
  const {
    data: dataUpload,
    isLoading: isLoadingUpload,
    isError: isErrorUpload,
    error: errorUpload,
    mutateAsync: mutateAsyncUpload,
    isSuccess: isSuccessUpload,
  } = postUpload;

  const {
    isSuccess,
    isLoading: isLoadingPost,
    isError: isErrorPost,
    error: errorPost,
    mutateAsync,
  } = postProfile;

  useEffect(() => {
    setValue("name", !isLoading ? data && data.name : "");
    setValue("address", !isLoading ? data && data.address : "");
    setValue("phone", !isLoading ? data && data.phone : "");
    setValue("bio", !isLoading ? data && data.bio : "");
  }, [isLoading, setValue, data]);

  const submitHandler = (data) => {
    if (!file && !fileLink) {
      mutateAsync({
        name: data.name,
        phone: data.phone,
        address: data.address,
        bio: data.bio,
        password: data.password,
      });
    } else {
      mutateAsync({
        name: data.name,
        phone: data.phone,
        address: data.address,
        bio: data.bio,
        password: data.password,
        image: fileLink,
      });
    }
  };

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      mutateAsyncUpload({ type: "image", formData });
    }
  }, [file]);

  useEffect(() => {
    if (isSuccessUpload) {
      setFileLink(
        dataUpload &&
          dataUpload.filePaths &&
          dataUpload.filePaths[0] &&
          dataUpload.filePaths[0].path
      );
    }
  }, [isSuccessUpload]);

  return (
    <FormContainer>
      <Helmet>
        <title>Perfil </title>
        <meta property="og:title" content="Profile" key="title" />
      </Helmet>

      {isErrorPost && <Message variant="danger">{errorPost}</Message>}
      {isErrorUpload && <Message variant="danger">{errorUpload}</Message>}
      {isError && <Message variant="danger">{error}</Message>}
      {isSuccess && (
        <Message variant="success">Usuário atualizado com sucesso</Message>
      )}

      {isLoading && <Spinner />}
      <form onSubmit={handleSubmit(submitHandler)}>
        {data && data.image && (
          <div className="d-flex justify-content-center">
            <img
              src={data && data.image}
              alt="avatar"
              className="rounded-circle"
              width="200"
              height="200"
            />
          </div>
        )}

        <div className="row">
          <div className="col-12">
            {inputText({
              register,
              errors,
              label: "Name",
              name: "name",
              placeholder: "Name",
            })}
          </div>
          <div className="col-md-6 col-12">
            {inputText({
              register,
              errors,
              label: "Endereço",
              name: "address",
              placeholder: "Endereço",
            })}
          </div>

          <div className="col-md-6 col-12">
            {inputTel({
              register,
              errors,
              label: "Telefone",
              name: "phone",
              placeholder: "+55 (00) 00000-0000",
            })}
          </div>
          {/* <div className="col-12">
            {inputNumber({
              register,
              errors,
              label: "Peso",
              name: "weight",
              placeholder: "55kg",
            })}
          </div> */}
          {/* <div className="col-12">
            {inputNumber({
              register,
              errors,
              label: "Altura",
              name: "height",
              placeholder: "1.70m",
            })}
          </div> */}
          {/* */}
          <div className="col-12">
            {inputTextArea({
              register,
              errors,
              label: "Bio",
              name: "bio",
              placeholder: "Observações de perfil",
            })}
          </div>

          <div className="col-12">
            {inputFile({
              register,
              errors,
              label: "Foto",
              name: "image",
              setFile,
              isRequired: false,
              placeholder: "Escolha uma imagem",
            })}
          </div>
          <div className="col-md-6 col-12">
            {inputPassword({
              register,
              errors,
              label: "Senha",
              name: "password",
              minLength: true,
              isRequired: false,
              placeholder: "Mantenha em branco se não quiser alterar a senha",
            })}
          </div>
          <div className="col-md-6 col-12">
            {inputPassword({
              register,
              errors,
              watch,
              name: "confirmPassword",
              label: "Confirmar Senha",
              validate: true,
              minLength: true,
              isRequired: false,
              placeholder: "Confirmar Senha",
            })}
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary form-control"
          disabled={isLoadingPost || isLoadingUpload}
        >
          {isLoadingPost || isLoadingUpload ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Atualizar"
          )}
        </button>
      </form>
    </FormContainer>
  );
};

export default Profile;
