import "react-confirm-alert/src/react-confirm-alert.css";
import { FaTrash } from "react-icons/fa";

export const Confirm = (action) => {
  return {
    customUI: ({ onClose }) => {
      return (
        <div className="px-5 py-3 shadow-lg text-center text-dark">
          <h1 className="tw-font-bold">Você tem certeza?</h1>
          <p>Tem certeza que deseja remover esse item?</p>
          <div className="btn-group d-flex justify-content-between">
            <button className="btn btn-outline-dark bg-sm" onClick={onClose}>
              Não
            </button>
            <button
              className="tw-flex tw-flex-wrap btn btn-outline-danger bg-sm ml-1"
              onClick={() => {
                action();
                onClose();
              }}
            >
              <div className="tw-w-full tw-flex tw-flex-wrap tw-items-center tw-justify-center">
                <span className="tw-pr-2">
                  <FaTrash />
                </span>{" "}
                Sim, desejo remover!
              </div>
            </button>
          </div>
        </div>
      );
    },
  };
};
