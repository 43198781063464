import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import _ from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import HeaderMax from "./Header";
import { Grid, Slug, Fade } from "mauerwerk";
import { Svg, Polygon } from "react-svg-path";
import Sidebar from "./Sidebar";
import moment from "moment";
import HeaderTop from "./HeaderTop";

import "moment/locale/pt-br";
import {
  UserOutlined,
  AppstoreOutlined,
  BarsOutlined,
  FileTextOutlined,
  SettingOutlined,
  CheckOutlined,
  AlertOutlined,
  BellOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Skeleton, Breadcrumb } from "antd";

import { getCardStyle } from "./utils";

const { Content, Sider } = Layout;

moment.locale("pt-br");

const categories = [];

const getStatusFromConditions = (conditions, alarme_id) => {
  const { comodo, people, mov, queda, cama } = conditions;

  let detected = 9;

  if (
    alarme_id &&
    alarme_id !== null &&
    alarme_id !== undefined &&
    alarme_id > -1
  ) {
    console.log("ALARM_ID_FOUND", alarme_id);
    let alarmFound = parseInt(alarme_id);
    if (alarmFound <= 9) {
      detected = alarmFound;
    }
  } else {
    console.log("ALARM_ID_NOT_FOUND", alarme_id);
    if (alarme_id === 0) {
      detected = 0;
      console.log("FORCING_ZERO");
    }
  }

  // if (Q_queda ==1):
  //     dic["alarme_id"]=8
  //   elif (B_queda ==1):
  //     dic["alarme_id"]=9
  //   elif (B_peop ==0 and Q_peop==0):
  //     dic["alarme_id"]=0
  //   elif (Q_peop ==1 and Q_cama==1 and Q_mov==0):
  //     dic["alarme_id"]=1
  //   elif (Q_peop ==1 and Q_cama==1 and Q_mov==1):
  //     dic["alarme_id"]=2
  //   elif (Q_peop ==1 and Q_mov==0 and B_peop ==0):
  //     dic["alarme_id"]=3
  //   elif (Q_peop ==1 and Q_mov==1 and B_peop ==0):
  //     dic["alarme_id"]=4
  //   elif (B_peop ==1 and B_mov==0 and Q_peop ==0):
  //     dic["alarme_id"]=5
  //   elif (B_peop ==1 and B_mov==1 and Q_peop ==0):
  //     dic["alarme_id"]=6
  //   elif (B_peop >1 or Q_peop > 1 or (B_peop > 0 and Q_peop> 0) ):
  //     dic["alarme_id"]=7

  if (detected < 9) return detected;

  if (queda === 1) detected = 8;
  if (queda === 1) detected = 9;
  if (comodo === 0 && people === 0) detected = 0;
  if (people === 1 && cama === 1 && mov === 0) detected = 1;
  if (people === 1 && cama === 1 && mov === 1) detected = 2;
  if (people === 1 && mov === 0 && comodo === 0) detected = 3;
  if (people === 1 && mov === 1 && comodo === 0) detected = 4;
  if (comodo === 1 && mov === 0 && people === 0) detected = 5;
  if (comodo === 1 && mov === 1 && people === 0) detected = 6;
  if (people > 1 || comodo > 1 || (people > 0 && comodo > 0)) detected = 7;

  // console.log(
  //   "DETECTED",
  //   detected,
  //   `${comodo} ${people} ${mov} ${queda} ${cama}`
  // );

  return detected;
};

function uniqBy(a, key) {
  var seen = {};
  return a.filter(function (item) {
    var k = key(item);
    return Object.prototype.hasOwnProperty.call(seen, k)
      ? false
      : (seen[k] = true);
  });
}

function getDateFromNow(timestamp) {
  return moment(timestamp).fromNow();
}

function getDateHeader() {
  // return Segunda-feira, 26 de agosto de 2023 às 15:15
  const date = new Date();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let finalDate = date.toLocaleDateString("pt-BR", options);
  finalDate = finalDate.charAt(0).toUpperCase() + finalDate.slice(1);
  return finalDate;
}

function getCategory(id) {
  return categories.find((e) => e.id === id);
}

const Cell = (props) => {
  // const bgGrid = `linear-gradient(to bottom, ${bg} 0%, ${bg} 100%)`;
  const bgGrid = `inherit`;

  const {
    toggle,
    name,
    level,
    path,
    description,
    maximized,
    bg,
    selected,
    onExpand,
    expanded,
    people,
    width,
    height,
    statusProps,
  } = props;

  const w = width || 263;
  const h = height || 176;
  const finalBg = statusProps?.bg || bg;

  useEffect(() => {
    if (!expanded && maximized) {
      toggle(undefined);
    }
  }, [expanded, maximized, toggle]);

  return (
    <Link to={path || undefined}>
      <div
        className="cell relative"
        style={{
          backgroundImage: bgGrid,
          cursor: !maximized ? "pointer" : "auto",
        }}
        onClick={
          !maximized && level > 0
            ? () => {
                onExpand(props);
                // toggle();
              }
            : undefined
        }
      >
        <div className="bg absolute w-full h-full z-0 flex items-center justify-center card card-on-grid">
          <Fade show={!maximized}>
            <Svg width={w} height={h} className="">
              <Polygon
                points={[
                  [0, h],
                  [w - 33, h],
                  [w, h - 36],
                  [w, 0],
                  [0, 0],
                ]}
                stroke={finalBg}
                strokeWidth={6}
                fill={selected ? finalBg : "#ffffff"}
              />
            </Svg>
          </Fade>
        </div>
        <Fade show={maximized} delay={maximized ? 400 : 0}>
          <div className={`details ${maximized ? "details_bg" : ""}`}>
            <Slug delay={!maximized ? 600 : 0}>
              {/* <div className="circle" style={{ background: css }} /> */}
              {/*<div className="close">
               <CloseOutlined
                type="close"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onExpand(null);
                  toggle();
                }}
              /> 
            </div>*/}
              <h1 className="titles-color text-4xl lato-bold">{name}</h1>
              <p className="subtitles-color text-md text-3xl lato-light">
                {description}
              </p>
            </Slug>
          </div>
        </Fade>
        <Fade
          show={!maximized}
          from={{ opacity: 0, transform: "translate3d(0,140px,0)" }}
          enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
          leave={{ opacity: 0, transform: "translate3d(0,-50px,0)" }}
          delay={maximized ? 0 : 400}
        >
          <div
            style={{ width: "260px" }}
            className=" h-full relative select-none"
          >
            {/* <div className="default">{room}</div> */}
            {level > 0 && (
              // left label
              <div
                style={{
                  position: "absolute",
                  left: "5%",
                  top: "12%",
                  fontSize: "16px",
                }}
                className="lato-bold text-black text-right"
              >
                <span
                  className={`mr-2 ${
                    selected ? "text-white" : "text-gray-400"
                  } text-sm`}
                >
                  {statusProps?.comodo || ""}
                </span>{" "}
              </div>
            )}
            {level > 0 && people > 0 && statusProps.icone2 && (
              // left label
              <div
                style={{
                  position: "absolute",
                  right: "0%",
                  top: "8%",
                }}
                className=""
              >
                <img
                  className="w-10 h-10 pr-2 object-contain"
                  src={`/app/file-manager/get/${statusProps?.icone2}`}
                  alt={statusProps?.texto2}
                />
              </div>
            )}
            <div
              style={{
                position: "absolute",
                right: "10%",
                top: "40%",
                width: "100px",
                lineHeight: "17px",
                fontSize: "17px",
              }}
              className="lato-black text-black text-left "
            >
              {statusProps?.texto1 || name}
            </div>
            {level > 0 && (
              <div
                style={{
                  position: "absolute",
                  left: "6%",
                  bottom: "15%",
                  width: "80%",
                  lineHeight: "14px",
                  fontSize: "16px",
                }}
                className="lato-bold text-gray-700 text-left "
              >
                {statusProps?.texto2} {`${people > 0 ? "" : ""}`}
              </div>
            )}
            {level > 0 && (
              <img
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "20%",
                  zIndex: "1",
                }}
                className="w-12 object-contain"
                src={`/app/file-manager/get/${statusProps?.icone1}`}
                alt={statusProps?.texto1}
              />
            )}
            {level === 0 && (
              <img
                src="/assets/images/home-icon.svg"
                alt={name}
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "20%",
                  zIndex: "1",
                }}
                className="w-12 object-contain"
              />
            )}
            <svg
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 79 77"
              width="79"
              height="77"
              style={{
                fill: !selected ? finalBg : "#ffffff",
                position: "absolute",
                top: "30%",
                left: "15%",
                zIndex: "0",
              }}
            >
              <path
                id="graf amarelo"
                d="m3.8 40.3l6.9-36.4 32.4 2.9 32.5 19.8-21.8 29.3-29.2 17.7z"
              />
            </svg>
          </div>
        </Fade>
      </div>
    </Link>
  );
};

const generateFilters = (data) => {
  const filters = data.map((d) => {
    return { id: d.statusId, name: d.name, checked: true };
  });
  // remove duplicates
  return uniqBy(filters, (e) => e.id);
};

const updateFilters = (oldFilters, data) => {
  const newFilters = data.map((d) => {
    return { id: d.statusId, name: d.name, checked: true };
  });
  const filters = oldFilters.map((f) => {
    const found = data.find((d) => d.statusId === f.id);
    if (found) {
      return { ...f, name: found.name };
    }
    return { id: f.statusId, name: f.name, checked: true };
  });
  const final = [...filters, ...newFilters];
  // remove duplicates
  return uniqBy(final, (e) => e.id);
};

function GridContent({
  dataSRC,
  user_building,
  columns,
  onExpand,
  expanded,
  links,
}) {
  const [state, setState] = useState({
    data: dataSRC,
    columns: columns || 4,
    margin: 20,
    filter: "",
    height: false,
    sortBy: "timestamp",
    filters: [],
    lastUpdate: new Date().getTime(),
  });

  const [breadcrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    if (state.columns !== columns) {
      console.log("SETTING_COLS_COLUMNS_UPDATED", columns);
      setState(
        _.merge({}, state, {
          columns: columns,
          lastUpdate: new Date().getTime(),
        })
      );
    }
  }, [columns, state]);

  useEffect(() => {
    setState(
      _.merge({}, state, {
        data: dataSRC,
        filters:
          state.filters.length < 1 && user_building !== "general"
            ? generateFilters(dataSRC)
            : updateFilters(state.filters, dataSRC),
      })
    );
    console.log("DATA_SRC_CONTENT", dataSRC);
  }, [dataSRC, user_building]);

  useEffect(() => {
    console.log("UPDATING_LINKS", links);
    setBreadCrumbs(links);
  }, [links]);

  // update state with lodash
  const search = (e) =>
    setState(_.merge({}, state, { filter: e.target.value }));

  const setSort = (e) => {
    setState(_.merge({}, state, { sortBy: e.key }));
    onExpand(null);
  };

  const shuffle = () =>
    setState(_.merge({}, state, { data: _.shuffle(state.data) }));

  const setFilters = (e) => {
    setState(_.merge({}, state, { filters: e }));
    onExpand(null);
  };

  const setColumns = (e) =>
    setState(_.merge({}, state, { columns: parseInt(e.key) }));
  const setMargin = (e) =>
    setState(_.merge({}, state, { margin: parseInt(e.key) }));
  const setHeight = (e) => {
    console.log(e);
    setState(_.merge({}, state, { height: e }));
  };

  let data =
    state?.data?.filter(
      (d) => d.name.toLowerCase().indexOf(state.filter) !== -1
    ) || [];

  data = data.filter((d) => {
    let selected = state.filters.filter((e) => e.checked);
    if (selected.length === 0) return true;
    return selected.some((e) => e.id === d.statusId);
  });

  data = data.sort((a, b) => {
    if (a[state.sortBy] < b[state.sortBy]) return -1;
    if (a[state.sortBy] > b[state.sortBy]) return 1;
    return 0;
  });

  function itemRender(item, params, items, paths) {
    console.log("ITEMS_CENTER_", params, items, paths);
    const last = items.indexOf(item) === items.length - 1;
    return last ? (
      <span> {item.title} </span>
    ) : (
      <Link to={item.path}> {item.title} </Link>
    );
  }

  if (data.length > 0) {
    return (
      <div className="main">
        <HeaderMax
          {...state}
          search={search}
          shuffle={shuffle}
          setColumns={setColumns}
          setFilters={setFilters}
          setMargin={setMargin}
          setHeight={setHeight}
          setSort={setSort}
        />
        <div className="w-full px-10 py-4">
          <Breadcrumb itemRender={itemRender} items={breadcrumbs} />
        </div>
        <Grid
          className="grid"
          // Arbitrary data, should contain keys, possibly heights, etc.
          data={data}
          // Key accessor, instructs grid on how to fet individual keys from the data set
          keys={(d) => d.key}
          // Can be a fixed value or an individual data accessor
          heights={state.height ? (d) => d.height : 200}
          // Number of columns
          columns={state.columns}
          // Space between elements
          margin={state.margin}
          // Removes the possibility to scroll away from a maximized element
          lockScroll={false}
          // Delay when active elements (blown up) are minimized again
          closeDelay={400}
        >
          {(data, maximized, toggle) => (
            <Cell
              {...data}
              onExpand={onExpand}
              expanded={expanded}
              maximized={maximized}
              toggle={toggle}
            />
          )}
        </Grid>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-start justify-center">
      <HeaderMax
        {...state}
        search={search}
        shuffle={shuffle}
        setColumns={setColumns}
        setFilters={setFilters}
        setMargin={setMargin}
        setHeight={setHeight}
        setSort={setSort}
      />
      <div className="flex-1 p-24 flex items-center justify-center w-full flex-col">
        <h2 className="text-2xl lato-bold text-gray-400">
          Nenhum resultado encontrado
        </h2>
        {state.filters.filter((e) => e.checked).length > 0 && (
          <p className="lato-regular text-gray-600 mt-4 text-center">
            Você está usando {state.filters.filter((e) => e.checked).length}{" "}
            filtros: <br />
            {state.filters
              .filter((e) => e.checked)
              .map((it, index) => (
                <span key={`${it.name}-${index}`} className="lato-bold">
                  {it.name}
                  {index < state.filters.filter((e) => e.checked).length - 1
                    ? ", "
                    : ""}
                </span>
              ))}
          </p>
        )}
      </div>
    </div>
  );
}

function FooterMax() {
  return (
    <div className="w-full flex items-center justify-center">
      <div id="footerLogo" className="flex flex-row py-2 px-2">
        <img
          className="w-64 object-contain footer-logo"
          src="/assets/images/footer_logo.svg"
          alt="logo"
        />
      </div>
    </div>
  );
}

function CardSidebar({ onExpand, timeline }) {
  const [lastStatus, setLastStatus] = useState({
    name: "Carrengando...",
    room: "",
    stateTitle: "Aguarde...",
    bg: "#fa4a33",
    timestamp: new Date().getTime(),
  });

  useEffect(() => {
    if (timeline.length > 0) {
      setLastStatus(timeline[0]);
    }
  }, [timeline]);

  return (
    <div className="flex flex-col w-full h-full bg-white bg-grids p-2">
      <div className="w-full py-4 px-4 flex items-start">
        <CloseOutlined
          type="close"
          style={{ cursor: "pointer", fontSize: "24px" }}
          onClick={() => {
            onExpand(null);
          }}
        />
      </div>
      <div className="w-full flex justify-center lato-bold text-3xl">
        {lastStatus.room}
      </div>
      <div className="w-full flex justify-center px-8 mt-6">
        <div className="w-full flex justify-between flex-wrap flex-row items-center">
          <div className="flex-1 mr-4 flex flex-wrap items-center">
            <div
              style={{
                backgroundColor: lastStatus.bg,
              }}
              className="card-sidebar-title w-full flex items-center justify-between flex-wrap px-3"
            >
              <div className="py-2 pl-1 pr-3 rounded border-black border-1">
                {lastStatus.stateTitle === "Alerta!" && (
                  <AlertOutlined
                    style={{
                      fontSize: "14px",
                      color: "white",
                    }}
                  />
                )}
                {lastStatus.stateTitle !== "Alerta!" && (
                  <BellOutlined
                    style={{
                      fontSize: "14px",
                      color: "white",
                    }}
                  />
                )}
              </div>
              <div className="flex-1 text-white lato-black text-lg leading-4 mb-1">
                {lastStatus.name}
              </div>
              <div className="text-white lato-regular text-md">
                {getDateFromNow(lastStatus.timestamp)}
              </div>
            </div>
          </div>
          <div className="h-full flex items-center justify-center">
            <div className="bt-checker py-2 px-3 w-full h-full flex items-center justify-center bg-green-300 rounded clip-bottom-right">
              <CheckOutlined
                style={{
                  fontSize: "18px",
                  color: "green",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* TIMESERIES
      <div className="w-full flex flex-col px-8 mt-4">
        <div className="w-full lato-bold text-lg">Variações de status</div>
        <div className="w-full mt-4">
          <TimeSeries lastStatus={lastStatus} />
        </div>
      </div>
       */}
      <div className="w-full flex flex-col px-8 mt-4">
        <div className="w-full lato-bold text-lg">Atividade recente</div>
        <div
          style={{ height: 300 }}
          className="w-full mt-4 flex flex-col overflow-auto"
        >
          {timeline.map((it, index) => (
            <div key={`${it.name}-${index}`} className="w-full">
              {/* TIMELINE ITEM */}

              <div
                style={{
                  backgroundColor: "rgba(228,228,228,0.10)",
                }}
                className="w-full flex flex-wrap justify-between items-center select-none cursor-pointer"
              >
                {it.stateCod === "danger" && (
                  <div className="py-2 px-3">
                    <AlertOutlined
                      style={{
                        fontSize: "14px",
                        color: it.bg,
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    fontSize: "14px",
                    color: it.bg,
                  }}
                  className="lato-black text-xl flex-1 text-left px-2"
                >
                  {it.stateTitle}
                </div>

                <div className="lato-regular text-md px-3 relative flex-1">
                  <div className="w-full flex flex-wrap justify-end">
                    <div className="pr-4 text-gray-500">
                      {getDateFromNow(it.timestamp)}{" "}
                    </div>
                    <div className="w-6 h-6 rounded bg-blue-300 flex items-center justify-center opacity-0 hover:opacity-100">
                      <CheckOutlined style={{ color: "#ffffff" }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex mt-2 flex-col">
                <div
                  style={{
                    fontSize: "16px",
                  }}
                  className="w-full lato-black text-xl flex-1 text-left px-2"
                >
                  {it.name} - {it.room}
                </div>
                <div
                  style={{
                    fontSize: "16px",
                  }}
                  className="w-full lato-regular text-xl flex-1 text-left px-2"
                >
                  {it.description}
                </div>
              </div>

              {/* END TIMELINE ITEM */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Home() {
  // get params
  const { user_codId, user_level, user_building } = useParams();
  const totalParams = useParams();

  const history = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [collapsed, setCollapsed] = useState(true);
  const [currentCard, setCard] = useState(null);
  const [columns, setColumns] = useState(4);
  const [dataSRC, setDataSRC] = useState([]);
  const [income, setIncome] = useState([]);
  const [selected, setSelected] = useState(null);
  const [notificationsBell, setNotificationsBell] = useState([]);

  const [links, setLinks] = useState([
    {
      title: "Franqueados",
      path: `/dashboard/${user_codId}/general`,
    },
  ]);

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
      style: {
        itemSelectedColor: "#154eed",
        itemSelectedBg: "154eed",
      },
    };
  }

  const sideMenu = [
    {
      ...getItem("Galeria", "1", <AppstoreOutlined />),
      onClick: () => {
        console.log("SETTING_COLS_BT", 4);
        setColumns(4);
        onExpand(null);
      },
    },
    {
      ...getItem("Lista", "2", <BarsOutlined />),
      onClick: () => {
        console.log("SETTING_COLS_BT", 1);
        onExpand(null);
        setColumns(1);
      },
    },
    getItem("User", "sub1", <UserOutlined />, [
      {
        ...getItem("Sair", "3"),
        onClick: () => {
          localStorage.removeItem("chatConnected");
          window.location.href = "/";
          // history.push(`/`);
        },
      },
    ]),
    getItem("Configurações", "sub2", <SettingOutlined />, [
      getItem("Configurações", "6"),
      {
        ...getItem("Notificações", "8"),
        onClick: () => {
          setIsOpen(true);
        },
      },
    ]),

    {
      ...getItem("Alarmes", "9", <FileTextOutlined />),
      onClick: () => {
        history.push(`/rules/${user_codId}`);
      },
    },
  ];

  const getCols = () => {
    return columns;
  };

  const onExpand = (e) => {
    console.log("onExpand", e);
    if (e !== null) {
      setCollapsed(false);
      setCard(e);
      setSelected(e.room);
    } else {
      setCollapsed(true);
      setCard(null);
      setSelected(null);
    }
    clearInterval(window.gridTimeout);
    window.gridTimeout = setTimeout(() => {
      setWrapperHeight(getCols());
    }, 1000);
  };

  function setWrapperHeight(oldCols) {
    const wrapper = document.getElementById("gridContentWrapper");
    if (wrapper) {
      wrapper.style.height = `${window.innerHeight - 200}px`;
      // card width is 263px
      const newCols = Math.floor(wrapper.clientWidth / (263 + 20));
      if (newCols < 6 && oldCols !== newCols && oldCols !== 1) {
        console.log("SETTING_COLS_F_EIGHT", oldCols, newCols);
        setColumns(newCols);
      }
    }
    // updateCardSize();
  }

  // function updateCardSize() {
  //   // iterate dataSRC and set height
  //   const wrapper = document.getElementById("gridContentWrapper");
  //   if (wrapper) {
  //     // proportional to column size
  //     const cardW = 263 / columns;

  //     const newData = dataSRC.map((e) => {
  //       return {
  //         ...e,
  //         width: cardW,
  //         height: 176,
  //       };
  //     });

  //     setDataSRC(newData);
  //   }
  // }

  useEffect(() => {
    // add resize listener
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        // setCollapsedLeft(true);
        onExpand(null);
        setColumns(1);
      }
      setWrapperHeight(getCols());
    });
    // set wrapper height after 1 sec

    setWrapperHeight(getCols());

    // remove resize listener
    return () => window.removeEventListener("resize", () => {});
  }, [getCols]);

  useEffect(() => {
    const generateCardsFromUnidade = (unidade) => {
      const cards = unidade.unidades.map((un, index) => {
        const treated = {
          id: un.slug,
          key: un.slug,
          path: `/dashboard/${user_codId}/${unidade.slug}${un.path}?selected=${un.codId}`,
          description: unidade.title,
          name: un.title,
          css: "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
          height: 176,
          width: 263,
          room: un.slug,
          timestamp: new Date().getTime(),
          statusId: 0,
          read: false,
          level: 0,
          selected: false,
          ...un.unityStatus,
        };
        console.log("CARD_DATA", index, un, treated);
        return treated;
      });

      return cards;
    };

    const generateCardsFromRooms = (rooms) => {
      const cards = [];

      Object.keys(rooms)
        .filter((key) => key !== "alarme_id" && key !== "timestamp")
        .forEach((key, index) => {
          let statusId = 1;
          const alarme_id = rooms[key]?.alarme_id;
          const residente = rooms[key]?.residente || "";

          console.log("ROOM_CARD_DATA", key, rooms[key], alarme_id);

          statusId = getStatusFromConditions(rooms[key], alarme_id);

          const statusProps = rooms[key].status;

          cards.push({
            id: `${key}-${index}`,
            key: key,
            name: statusProps?.texto1 || "Carregando...",
            description: statusProps?.texto2 || "Carregando...",
            css: "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
            height: 176,
            width: 263,
            level: 1,
            people: rooms[key].people || 0,
            room: rooms[key].comodo,
            timestamp: new Date().getTime(),
            statusId: statusId,
            alarme_id: alarme_id,
            residente,
            read: false,
            statusProps,
            selected: false,
            ...getCardStyle(statusId, key),
          });
        });
      return cards;
    };
    const treatDataSRC = (newData) => {
      console.log("treatDataSRC", newData);

      let unidades = [];
      let unidade = null;
      if (newData && newData.unidades && newData.unidades.length > 0) {
        unidades = newData.unidades;
      }

      if (newData.isParent) {
        setLinks([
          {
            title: "Franqueados",
            path: `/dashboard/${user_codId}/general`,
          },
          {
            title: newData.title,
            path: `/dashboard/${user_codId}/${newData.slug}`,
          },
        ]);
      } else {
        setLinks([
          {
            title: "Franqueados",
            path: `/dashboard/${user_codId}/general`,
          },
          {
            title: newData.title,
            path: `/dashboard/${user_codId}/${newData.slug}`,
          },
        ]);
      }

      if (user_building && user_building !== "general") {
        if (newData.isParent) {
          console.log("SEARCHING_UNIDADE", user_building, unidades);
          unidade = unidades.find(
            (u) => u.slug === user_building.replace("unidade-", "")
          );
          if (unidade) {
            console.log("FOUND_UNIDADE", unidade);
            setDataSRC(generateCardsFromRooms(unidade.payload));
            setLinks([
              {
                title: "Franqueados",
                path: `/dashboard/${user_codId}/general`,
              },
              {
                title: newData.title,
                path: `/dashboard/${user_codId}/general`,
              },
              {
                title: unidade.title,
                path: `/dashboard/${user_codId}/unidade-${unidade.slug}`,
              },
            ]);
          }
        } else {
          console.log("SEARCHING_UNIDADE", user_building, unidades);
          unidade = unidades
            .filter((un) => un.codId === parseInt(user_codId))
            .find((u) => u.slug === user_building.replace("unidade-", ""));
          if (unidade) {
            console.log("FOUND_UNIDADE", unidade);
            setDataSRC(generateCardsFromRooms(unidade.payload));
            setLinks([
              {
                title: "Franqueados",
                path: `/dashboard/${user_codId}/general`,
              },
              {
                title: newData.title,
                path: `/dashboard/${user_codId}/general`,
              },
              {
                title: unidade.title,
                path: `/dashboard/${user_codId}/unidade-${unidade.slug}`,
              },
            ]);
          }
        }
      } else {
        if (newData.isParent) {
          if (unidades.length > 0) {
            const newDataX = generateCardsFromUnidade({
              title: newData.title,
              slug: newData.slug,
              unidades: unidades.map((un) => {
                return {
                  title: un.title,
                  slug: un.slug,
                  path: `/unidade-${un.slug}`,
                  codId: un.codId,
                  unityStatus: un.unityStatus,
                };
              }),
            });

            console.log("SETTING_NEWDATA", newDataX, {
              user_codId,
              user_level,
              user_building,
            });

            setDataSRC(newDataX);
          }
        } else {
          if (unidades.length > 0) {
            const newDataX = generateCardsFromUnidade({
              title: newData.title,
              slug: newData.slug,
              unidades: unidades
                .filter((un) => un.codId === parseInt(user_codId))
                .map((un) => {
                  return {
                    title: un.title,
                    slug: un.slug,
                    path: `/unidade-${un.slug}?selected=${un.codId}`,
                    unityStatus: un.unityStatus,
                  };
                }),
            });

            console.log("SETTING_NEWDATA", newDataX, {
              user_codId,
              user_level,
              user_building,
            });

            setDataSRC(newDataX);
          }
        }
      }

      // setDataSRC(newData);
    };

    treatDataSRC(income);
  }, [income, user_building, user_level, user_codId, totalParams]);

  const filtered = uniqBy([...dataSRC], (e) => e.room)
    .map((f) => {
      const model = f;
      console.log("MAPPING_MODEL", model);
      if (selected === model.room) {
        model.selected = true;
      } else {
        model.selected = false;
      }
      return model;
    })
    .filter((e) => {
      if (user_building && user_building !== "general") {
        return e.level > 0;
      } else {
        return e.level === 0;
      }
    });

  return (
    <div
      style={{
        backgroundImage: `url('/assets/images/bg_general.png')`,
      }}
      className="w-full h-full flex flex-col items-center justify-center bg-anglis p-4"
    >
      <Sidebar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getDateHeader={getDateHeader}
        getDateFromNow={getDateFromNow}
        getStatusFromConditions={getStatusFromConditions}
        getCardStyle={getCardStyle}
        getCategory={getCategory}
        onIncome={(newData, nots) => {
          // newData.forEach((e) => {
          //   if (e.room === selected) {
          //     setCard(e);
          //   }
          // });

          if (newData) {
            console.log("INCOME_MODEL", newData);
            setIncome(newData);
          }
          if (nots) {
            console.log("INCOME_MODEL_NOTS", nots);
            setNotificationsBell(nots);
          }
        }}
        onConnection={(status) => {
          console.log("CONNECTION_STATUS", status);
        }}
      />
      <HeaderTop
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dataSRC={dataSRC}
        getDateHeader={getDateHeader}
        notificationsBell={notificationsBell}
        setNotificationsBell={setNotificationsBell}
      />
      <div className="w-full pt-4 flex-1">
        <Layout
          style={{
            height: "100%",
            backgroundColor: "transparent",
          }}
        >
          <Sider
            width={100}
            collapsed={true}
            trigger={null}
            onCollapse={(value) => {
              console.log("Collapse", value);
            }}
            style={{
              overflow: "hidden",
              height: "100%",
              left: 0,
              backgroundColor: "transparent",
            }}
          >
            <div className="w-full h-full flex items-center justify-center">
              <Menu
                style={{
                  backgroundColor: "transparent",
                  itemSelectedColor: "#154eed",
                  itemSelectedBg: "154eed",
                }}
                defaultSelectedKeys={["1"]}
                mode="inline"
                items={sideMenu}
              />
            </div>
          </Sider>
          <Layout
            style={{
              backgroundColor: "transparent",
            }}
          >
            <Content
              style={{
                marginRight: collapsed ? "44px" : "16px",
                marginLeft: "16px",
                background: "transparent",
              }}
            >
              <div className="w-full h-full flex items-start justify-center flex flex-row flex-wrap bg-white bg-grids p-2">
                <div
                  id="gridContentWrapper"
                  style={{ height: "500px" }}
                  className="w-full overflow-auto mr-3"
                >
                  {filtered.length < 1 && (
                    <div className="w-full p-24">
                      <div className="mb-24 flex flex-wrap w-full justify-between">
                        {/* create a loop with the columns size */}
                        {[...new Array(columns)].map((i, index) => {
                          return (
                            <div key={`${index}`} className="mb-20">
                              <Skeleton.Node active={true}>
                                <IdcardOutlined
                                  style={{
                                    fontSize: 40,
                                    color: "#bfbfbf",
                                    marginLeft: 40,
                                    marginRight: 40,
                                  }}
                                />
                              </Skeleton.Node>
                            </div>
                          );
                        })}
                      </div>
                      <div className="mb-24 flex flex-wrap w-full justify-between">
                        {/* create a loop with the columns size */}
                        {[...new Array(columns)].map((i, index) => {
                          return (
                            <div key={`${index}`} className="mb-20">
                              <Skeleton.Node active={true}>
                                <IdcardOutlined
                                  style={{
                                    fontSize: 40,
                                    color: "#bfbfbf",
                                    marginLeft: 40,
                                    marginRight: 40,
                                  }}
                                />
                              </Skeleton.Node>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {filtered.length > 0 && (
                    <GridContent
                      links={links}
                      user_building={user_building}
                      dataSRC={filtered}
                      columns={columns}
                      expanded={!collapsed}
                      onExpand={onExpand}
                    />
                  )}
                </div>
              </div>
            </Content>
          </Layout>
          <Sider
            collapsible
            width={collapsed ? 0 : 540}
            collapsedWidth={0}
            trigger={null}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            style={{
              overflow: "hidden",
              // height: "100vh",
              // position: "fixed",
              // right: 0,
              width: collapsed ? "0px !important" : "inherit",
              minWidth: collapsed ? "0px !important" : "500px",
              maxWidth: collapsed ? "0px !important" : "500px",
              backgroundColor: "transparent",
            }}
          >
            <div className="w-full h-full px-4">
              {currentCard && (
                <CardSidebar
                  timeline={dataSRC
                    .slice(0, 20)
                    .filter((e) => e.room === currentCard.room)}
                  onExpand={onExpand}
                />
              )}
            </div>
            {/* <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
        /> */}
          </Sider>
        </Layout>
      </div>
      <FooterMax />
    </div>
  );
}

export default Home;
