/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import { Search } from "..";
import {
  Table,
  Button,
  Modal,
  Transfer,
  Flex,
  // Switch,
  Tabs,
  notification,
  Alert,
  Descriptions,
} from "antd";
import React, { useState, useMemo, createContext } from "react";
import { EditOutlined } from "@ant-design/icons";

const Context = createContext({
  name: "Default",
});

const ViewResidentes = ({
  data,
  // editHandler,
  // deleteHandler,
  // isLoadingDelete,
  useUserResidentesHook,
  setQ,
  q,
  searchHandler,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadings, setLoadings] = useState({});
  const [modalSelected, setModalSelected] = useState(null);

  const columns = [
    {
      title: "Franquia / Franqueado",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "codId",
      dataIndex: "codId",
      key: "codId",
    },
    {
      title: "_id",
      dataIndex: "_id",
      key: "_id",
    },

    {
      title: "Ações",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div key={record._id} className="flex flex-wrap">
          <div className="ms-auto">
            {/* <Button type="primary" onClick={showModal}>
              Open Modal
            </Button> */}
            <Button
              type="primary"
              size="small"
              icon={<EditOutlined />}
              title={`Editar ${record.nome}`}
              loading={loadings[record._id]}
              onClick={() => enterLoading(record._id)}
            >
              {loadings[record._id]}
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = prevLoadings;
      newLoadings[index] = true;
      console.log("index_on", index, loadings);
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = prevLoadings;
        newLoadings[index] = false;
        console.log("index_off", index, loadings);
        showModal(index);
        return newLoadings;
      });
    }, 1000);
  };

  const showModal = (index) => {
    setModalSelected(findResidente(index));
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const TableTransfer = (props) => {
    const { leftColumns, rightColumns, ...restProps } = props;
    return (
      <Transfer
        style={{
          width: "100%",
        }}
        titles={["Usuários", "✅ Permitidos"]}
        {...restProps}
      >
        {({
          direction,
          filteredItems,
          onItemSelect,
          onItemSelectAll,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled,
        }) => {
          const columns = direction === "left" ? leftColumns : rightColumns;
          const rowSelection = {
            getCheckboxProps: () => ({
              disabled: listDisabled,
            }),
            onChange(selectedRowKeys) {
              onItemSelectAll(selectedRowKeys, "replace");
            },
            selectedRowKeys: listSelectedKeys,
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE,
            ],
          };
          return (
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              size="small"
              scroll={{ x: "100%", y: 200 }}
              style={{
                pointerEvents: listDisabled ? "none" : undefined,
              }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) {
                    return;
                  }
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          );
        }}
      </Transfer>
    );
  };

  const ModalComp = ({ modalData }) => {
    // updateUserResidente, postUserResidente
    const { getUserResidentes, updateUserResidente } = useUserResidentesHook({
      page: 0,
      q: "uxx",
      id: modalData?._id,
    });

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement) => {
      api.info({
        message: `${modalData?.nome} atualizado.`,
        description: (
          <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>
        ),
        placement,
      });
    };
    const contextValue = useMemo(
      () => ({
        name: `${modalData?.nome} atualizado.`,
      }),
      []
    );

    // const {
    //   // isLoading: isLoadingPost,
    //   // isError: isErrorPost,
    //   // error: errorPost,
    //   // isSuccess: isSuccessPost,
    //   mutateAsync: mutateAsyncPost,
    // } = postUserResidente;

    const {
      // isLoading: isLoadingUpdate,
      // isError: isErrorUpdate,
      // error: errorUpdate,
      // isSuccess: isSuccessUpdate,
      mutateAsync: mutateAsyncUpdate,
    } = updateUserResidente;

    const submitHandler = (data) => {
      // console.log("data", data, mutateAsyncUpdate);
      // openNotification("bottomRight");
      mutateAsyncUpdate(data)
        .then(() => {
          openNotification("bottomRight");
        })
        .catch(() => {
          openNotification("bottomRight");
        });
    };

    const { data: dataUser } = getUserResidentes;
    // const [targetKeys] = useState([]);
    const [disabled] = useState(false);
    // const [targetKeys, setTargetKeys] = useState([]);

    const onChange = (nextTargetKeys) => {
      console.log("nextTargetKeys", nextTargetKeys);
      submitHandler({
        residente: modalData?._id,
        users: nextTargetKeys,
      });
      // setTargetKeys(nextTargetKeys);
    };
    // const toggleDisabled = (checked) => {
    //   setDisabled(checked);
    // };

    // const mockTags = ["cat", "dog", "bird"];
    // const mockData = Array.from({
    //   length: 20,
    // }).map((_, i) => ({
    //   key: i.toString(),
    //   user: `content${i + 1}`,
    //   // description: `description of content${i + 1}`,
    //   email: mockTags[i % 3],
    // }));
    const columns = [
      {
        dataIndex: "name",
        title: "Usuário",
      },
      {
        dataIndex: "email",
        title: "Email",
        render: (tag) => <span>{tag.toLowerCase()}</span>,
      },
      {
        dataIndex: "_id",
        title: "Id",
      },
    ];
    const filterOption = (input, item) =>
      item.name?.includes(input) ||
      item.email?.includes(input) ||
      item._id?.includes(input);

    const isChild = (record) => record.children && record.children.length > 0;

    const onChangeTab = (key) => {
      console.log(key);
    };

    // useEffect(() => {
    //   if (!id && isModalOpen) {
    //     // getUserResidentes.mutateAsync(modalSelected._id);
    //     editHandler(modalSelected);
    //   }
    // }, [id]);

    return (
      <Context.Provider value={contextValue}>
        <Modal
          title={"Configurações de Residente"}
          open={isModalOpen && modalData}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
          maskClosable={false}
          footer={null}
        >
          {contextHolder}
          <Tabs
            defaultActiveKey="1"
            defaultOpenKeys={["1"]}
            items={[
              {
                key: "1",
                label: "Permissões de usuário",
                children: (
                  <div className="">
                    <div className="tw-flex tw-flex-col tw-w-full tw-p-4 tw-border-1 tw-border-rounded tw-border-gray-300 tw-bg-gray-100">
                      <div className="tw-w-full tw-font-bold tw-text-2xl tw-text-gray-500">
                        {modalData && modalData.nome}
                      </div>

                      {modalData && (
                        <Descriptions
                          layout="vertical"
                          size="small"
                          // title={`${modalData.nome} - ${modalData.codId}`}
                        >
                          {/* <Descriptions.Item
                  label={isChild(modalData) ? "Franqueado" : "Residente"}
                >
                  {modalData.nome || "Sem nome"}
                </Descriptions.Item> */}
                          {/* <Descriptions.Item label="É franqueado">
                {isChild(modalData) ? "Sim" : "Não"}
              </Descriptions.Item> */}

                          <Descriptions.Item label="_id">
                            {modalData._id}
                          </Descriptions.Item>

                          <Descriptions.Item label="codId">
                            {modalData.codId}
                          </Descriptions.Item>

                          {!isChild(modalData) && modalData.parent && (
                            <Descriptions.Item label="codId Pai">
                              {modalData.parent.toString()}
                            </Descriptions.Item>
                          )}

                          <Descriptions.Item label="CNPJ">
                            {modalData.cnpj || "Sem CNPJ"}
                          </Descriptions.Item>

                          {isChild(modalData) && (
                            <Descriptions.Item label="Total de filhos">
                              {modalData.children && modalData.children.length}
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      )}
                      {/* <p>{JSON.stringify(modalData)}</p> */}
                    </div>
                    <div className="tw-w-full tw-flex tw-justify-between tw-py-4">
                      <div className="tw-w-1/2  tw-flex tw-flex-col tw-px-2 tw-pr-4">
                        <div className="tw-mb-2 mt-4 tw-font-bold tw-text-lg tw-w-full">
                          Lista de usuários
                        </div>
                        <div className="tw-w-full tw-mb-4 tw-text-xs tw-pr-2">
                          <Alert
                            message={`Selecione os usuários que terão acesso a este ${
                              isChild(modalData)
                                ? "franqueado (inclui todos os residentes)"
                                : "residente"
                            }`}
                            type="warning"
                            showIcon
                          />
                        </div>
                      </div>
                      <div className="tw-w-1/2 tw-px-2 tw-flex tw-flex-col tw-border-l-2 tw-border-gray-300">
                        <div className="tw-mb-2 tw-pl-4 mt-4 tw-font-bold tw-text-lg tw-w-full">
                          Usuários permitidos
                        </div>
                        <div className="tw-w-full tw-pl-4 tw-mb-4 tw-text-xs ">
                          <Alert
                            message={`Os usuários selecionados possum acesso a este ${
                              isChild(modalData) ? "franqueado" : "residente"
                            }`}
                            type="success"
                            showIcon
                          />
                        </div>
                      </div>
                    </div>
                    <Flex align="start" gap="middle" vertical>
                      <TableTransfer
                        dataSource={
                          (dataUser &&
                            dataUser?.data.users &&
                            dataUser?.data.users.map((user) => ({
                              ...user,
                              key: user._id,
                            }))) ||
                          []
                        }
                        targetKeys={
                          dataUser && dataUser?.data.residenteUsers
                            ? dataUser?.data.residenteUsers
                            : []
                        }
                        disabled={disabled}
                        showSearch
                        showSelectAll={false}
                        onChange={onChange}
                        filterOption={filterOption}
                        leftColumns={columns}
                        rightColumns={columns}
                      />
                      {/* <Switch
            unCheckedChildren="disabled"
            checkedChildren="disabled"
            checked={disabled}
            onChange={toggleDisabled}
          /> */}
                    </Flex>
                  </div>
                ),
              },
              !isChild(modalData) && {
                key: "2",
                label: "Cômodos",
                children: (
                  <div className="">
                    <div className="tw-flex tw-flex-col tw-w-full tw-p-4 tw-border-1 tw-border-rounded tw-border-gray-300 tw-bg-gray-100">
                      <div className="tw-w-full tw-font-bold tw-text-2xl tw-text-gray-500">
                        {modalData && modalData.nome}
                      </div>

                      {modalData && (
                        <Descriptions
                          layout="vertical"
                          size="small"
                          // title={`${modalData.nome} - ${modalData.codId}`}
                        >
                          {/* <Descriptions.Item
              label={isChild(modalData) ? "Franqueado" : "Residente"}
            >
              {modalData.nome || "Sem nome"}
            </Descriptions.Item> */}
                          {/* <Descriptions.Item label="É franqueado">
            {isChild(modalData) ? "Sim" : "Não"}
          </Descriptions.Item> */}

                          <Descriptions.Item label="_id">
                            {modalData._id}
                          </Descriptions.Item>

                          <Descriptions.Item label="codId">
                            {modalData.codId}
                          </Descriptions.Item>

                          {!isChild(modalData) && modalData.parent && (
                            <Descriptions.Item label="codId Pai">
                              {modalData.parent.toString()}
                            </Descriptions.Item>
                          )}

                          <Descriptions.Item label="CNPJ">
                            {modalData.cnpj || "Sem CNPJ"}
                          </Descriptions.Item>

                          {isChild(modalData) && (
                            <Descriptions.Item label="Total de filhos">
                              {modalData.children && modalData.children.length}
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      )}
                      {/* <p>{JSON.stringify(modalData)}</p> */}
                    </div>
                  </div>
                ),
              },
            ]}
            onChange={onChangeTab}
          />
        </Modal>
      </Context.Provider>
    );
  };

  const findResidente = (id) => {
    const found = data.data.find((residente) => residente._id === id);
    if (found) {
      return found;
    }
    const childFound = data.data
      .map((residente) => residente.children)
      .flat()
      .find((child) => child._id === id);
    if (childFound) {
      return childFound;
    }
    // check grandChild
    const grandChildFound = data.data
      .map((residente) => residente.children)
      .flat()
      .map((child) => child.children)
      .flat()
      .find((grandChild) => grandChild._id === id);
    if (grandChildFound) {
      return grandChildFound;
    }
    return {};
  };

  const treatDataTable = (income) => {
    const treatedTableData = income?.data
      ? income.data.map((item) => {
          let model = item;
          // delete model.children;
          return {
            ...model,
            key: item._id,
            children: item.children.map((child) => {
              return {
                ...child,
                key: child._id,
                children: child.children.map((grandChild) => {
                  return {
                    ...grandChild,
                    key: grandChild._id,
                  };
                }),
              };
            }),
          };
        })
      : [];

    return treatedTableData;
  };

  console.log("TREATED_TABLE_DATA", treatDataTable(data));

  const checkHasError = () => {
    if (data && data.name == "Error") {
      return (
        <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-text-red-500">
          <h3>Erro ao carregar dados.</h3>
        </div>
      );
    }
  };
  if (checkHasError()) {
    return <div className="tw-w-full tw-px-4">{JSON.stringify(data)}</div>;
  }

  return (
    <div className="tw-w-full tw-px-4">
      {modalSelected && <ModalComp modalData={modalSelected} />}

      <div className="tw-w-full tw-flex tw-flex-col tw-bg-gray-200 tw-py-4 tw-items-center">
        <section className="tw-w-full tw-flex tw-flex-col tw-pb-4 ">
          <h3 className="tw-text-xl tw-font-bold fw-light text-muted tw-text-center">
            Configurações de Residentes{" "}
            <sup className="fs-6"> [{data && data.data.length}] </sup>
          </h3>
        </section>
        {/* <button
          className="btn btn-outline-primary btn-sm shadow my-2"
          data-bs-toggle="modal"
          data-bs-target="#roleModal"
        >
          Adicionar novo cargo
        </button> */}
        <div className="tw-w-full tw-flex tw-justify-center">
          <Search
            placeholder="Pesquisar por nome"
            setQ={setQ}
            q={q}
            searchHandler={searchHandler}
          />
        </div>
      </div>
      <div className="tw-w-full tw-max-w-4xl tw-mx-auto tw-py-6">
        <Table
          columns={columns}
          expandable={{
            // expandedRowRender: () => null,
            rowExpandable: (record) =>
              record.children && record.children.length > 0,
          }}
          dataSource={treatDataTable(data)}
          bordered
        />
      </div>
    </div>
  );
};

export default ViewResidentes;
