import dynamicAPI from "./dynamicAPI";
import { useQuery, useMutation, useQueryClient } from "react-query";

const url = "/api/auth/user-residentes";

const queryKey = "user-residentes";

export default function useUserResidentesHook(props) {
  const { id } = props;
  const queryClient = useQueryClient();

  const getUserResidentes = useQuery(
    queryKey,
    async () => await dynamicAPI("get", `${url}/${id}`, {}),
    { retry: 0 }
  );

  const getUserResidenteById = useQuery(
    queryKey,
    async (id) => await dynamicAPI("get", `${url}/${id}`, {}),
    { retry: 0, enabled: !!id }
  );

  const updateUserResidente = useMutation(
    async (obj) => await dynamicAPI("post", `${url}/${obj.residente}`, obj),
    {
      retry: 3,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  const deleteUserResidente = useMutation(
    async (id) => await dynamicAPI("delete", `${url}/${id}`, {}),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  const postUserResidente = useMutation(
    async (obj) => await dynamicAPI("post", url, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  );

  return {
    getUserResidentes,
    updateUserResidente,
    deleteUserResidente,
    postUserResidente,
    getUserResidenteById,
  };
}
