const getCardStyle = (statusId) => {
  let stateCod = "alert";
  let stateTitle = "Atenção!";

  const cod = parseInt(statusId);

  // DEFAULT CLIENT
  if (cod === 0) {
    stateCod = "neutral";
    stateTitle = "Sem detalhes";
  }
  if (cod === 1) {
    stateCod = "normal";
    stateTitle = "Seguro";
  }
  if (cod === 2) {
    stateCod = "alert";
    stateTitle = "Atenção!";
  }
  if (cod === 3) {
    stateCod = "danger";
    stateTitle = "Alerta!";
  }

  if (stateCod === "neutral") return { bg: "#d4d4d4", stateCod, stateTitle };
  if (stateCod === "danger") return { bg: "#fa4a33", stateCod, stateTitle };
  if (stateCod === "alert") return { bg: "#ffdd00", stateCod, stateTitle };
  if (stateCod === "normal") return { bg: "#1ecfb8", stateCod, stateTitle };

  return {
    bg: "#ffffff",
    stateCod: stateCod,
    stateTitle: stateTitle,
  };
};

export { getCardStyle };
