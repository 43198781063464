import { Helmet } from "react-helmet";
import Home from "../../components/dashboard/Home";
const DashboardContainer = () => {
  return (
    <>
      <Helmet>
        <title>Anglis - Dashboard</title>
        <meta property="og:title" content="Anglis Dashboard" key="title" />
      </Helmet>
      <div
        className="d-flex justify-content-center align-items-center tw-h-screen"
        // style={{ height: "70vh" }}
      >
        {/* <span className="text-muted">
          <span className="tw-font-bold tw-text-4xl">DASHBOARD CONTENT </span>
        </span> */}
        <Home />
      </div>
    </>
  );
};

export default DashboardContainer;
