import { Search } from "..";
import { Table } from "antd";

const ViewUserProfiles = ({ data, setQ, q, searchHandler }) => {
  const columns = [
    {
      title: "Foto",
      dataIndex: "image",
      key: "image",
      width: 60,
      render: (_, userProfile) => (
        <span>
          <img
            width="30"
            height="30"
            src={userProfile.image}
            alt={userProfile.name}
            className="img-fluid rounded-pill"
          />
        </span>
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      sorter: true,
    },
    {
      title: "Endereço",
      dataIndex: "address",
      key: "address",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (_, user) => <a>{user.user.email}</a>,
    },
  ];
  return (
    <div className="tw-w-full">
      <div className="tw-w-full tw-flex tw-flex-col tw-bg-gray-200 tw-py-4 tw-items-center">
        <div className="d-flex align-items-center flex-column mb-2">
          <section className="tw-w-full tw-flex tw-flex-col tw-pb-4 ">
            <h3 className="tw-text-xl tw-font-bold fw-light text-muted tw-text-center">
              Perfis <sup className="fs-6"> [{data && data.total}] </sup>
            </h3>
          </section>

          <div className="col-auto">
            <Search
              placeholder="Pesquisar por nome"
              setQ={setQ}
              q={q}
              searchHandler={searchHandler}
            />
          </div>
        </div>
      </div>

      <div className="tw-w-full tw-max-w-4xl tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6">
        <div className="tw-w-full tw-flex tw-justify-end tw-mb-2">
          {/* <Button
            type="primary"
            shape="round"
            data-bs-toggle="modal"
            data-bs-target="#permissionModal"
            icon={<UserSwitchOutlined />}
            size={"default"}
            className="tw-mt-3"
          >
            Adicionar rota
          </Button> */}
        </div>
        <div className="tw-w-full">
          <Table
            columns={columns}
            pagination={{
              position: ["none"],
            }}
            dataSource={
              data && data.data
                ? data.data.map((user) => {
                    return {
                      key: user._id,
                      ...user,
                    };
                  })
                : []
            }
          />
        </div>
      </div>
      {/* <table className="table table-sm table-border">
        <thead className="border-0">
          <tr>
            <th>Foto</th>
            <th>Nome</th>
            <th>Endereço</th>
            <th>Telefone</th>
            <th>Email</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((userProfile) => (
              <tr key={userProfile._id}>
                <td>
                  <img
                    width="30"
                    height="30"
                    src={userProfile.image}
                    alt={userProfile.name}
                    className="img-fluid rounded-pill"
                  />
                </td>
                <td>{userProfile.name}</td>
                <td>{userProfile.address}</td>
                <td>{userProfile.phone}</td>
                <td>{userProfile.user && userProfile.user.email}</td>
              </tr>
            ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default ViewUserProfiles;
